import React,
{
  useEffect,
  useState,
} from 'react';

import {
  useTranslation,
} from 'react-i18next';

import {
  useDispatch,
  useSelector,
} from 'react-redux';

import {
  useHistory,
  useParams,
} from 'react-router-dom';

import {
  GetUserInWaitList,
  PostLeaveWaitList,
  PostToEnterWaitList,
} from '@noitada/axios-config/endpoints/wait-lists.endpoints';

import {
  RootState,
} from '@noitada/redux-config';

import {
  APP_MODAL_LOADING_ACTION,
  APP_SNACKBAR_ACTION,
} from '@noitada/redux-config/reducers/app.store';

import {
  ArrayColors,
} from '@noitada/shared/arrays';

import {
  Colors,
  Images,
} from '@noitada/shared/constants';

import EResponseStatus from '@noitada/shared/enums/Response/response-status.enum';

import {
  TranslateConfig,
} from '@noitada/shared/translations';

import {
  FormatIsoDateWithPatternDayjs,
} from '@noitada/shared/utils/date.utils';

import {
  treatErrorAndReturnColor,
  treatErrorAndReturnMessage,
} from '@noitada/shared/utils/errors.utils';

import AlertMessage from '../../../components/Alerts';

import {
  Body,
  Container,
  Content,
  Screen,
  SubTitle,
  Title,
} from '../../../components/Composh/web';

import {
  ButtonBack,
} from '../../../components/Controls';

import DetailsHeaderBlur from '../../../components/Details/DetailsHeaderBlur';
import EmptyContent from '../../../components/Empty';
import FooterChoose from '../../../components/Footers/FooterChoose';
import HeaderScreen from '../../../components/Headers/HeaderScreen';
import LoadingProgress from '../../../components/Loadings/LoadingProgress';
import LoadingScreen from '../../../components/Loadings/LoadingScreen';
import KnowApp from '../../../modals/KnowApp';

import NameRoutes from '../../../navigation/names';

import {
  ButtonText,
} from '../../../styles/styled.layout';

import {
  WaitListUserInsideView,
  WaitListUserDateView,
  WaitListUserDateText,
  WaitListUserTitle,
  WaitListSubtitleContainer,
  WaitListSubtitleText,
  WaitListUserCircle,
  WaitListUserTextCircle,
  WaitListUserExitText,
} from './styled';



export interface IRouteParams {
  id_wait_list: string;
}



const WaitListsUserScreen: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t: translate } = useTranslation();

  const params: IRouteParams = useParams();
  const { id_wait_list } = params;

  const urlScreen = window.location.href;
  const showKnowApp = urlScreen.includes('?qrcode=yes');


  const user = useSelector((state: RootState) => state.user.actualUser);
  const availableUser = user?.id;


  const waitListIdSearch = id_wait_list;

  const [statusKnowApp, setStatusKnowApp] = useState(true);

  const timeLimit = 5;
  const [timeUpdate, setTimeUpdate] = useState<number>(timeLimit);

  const [companySelected, setCompanySelected] = useState<any>(null);
  const [waitListObject, setWaitListObject] = useState<any>(null);
  const [waitListPosition, setWaitListPosition] = useState<any>(null);

  const [exitListModal, setExitListModal] = useState<boolean>(false);

  const [loading, setLoading] = useState(false);



  function showSnackBarProps(snackColor: string, snackText: string) {
    dispatch({
      type: APP_SNACKBAR_ACTION,
      payload: {
        visible: true,
        color: snackColor,
        text: snackText,
      },
    });
  }


  async function getPositionUserList() {
    try {
      const response = await GetUserInWaitList(waitListIdSearch, availableUser);
      const responseData = response?.data;

      if (!responseData) {
        setCompanySelected(null);
        setWaitListObject(null);
        setWaitListPosition(null);
        return;
      }

      setCompanySelected(responseData?.company);
      setWaitListObject(responseData?.waitList);
      setWaitListPosition(responseData?.userPosition);
    }
    catch (error: any) {
      console.error(error);
      setCompanySelected(null);
      setWaitListObject(null);
      setWaitListPosition(null);

      const getMessageError = treatErrorAndReturnMessage(error);
      const messageError = translate(getMessageError);
      const colorError = treatErrorAndReturnColor(error);
      showSnackBarProps(colorError, messageError);
    }
  };


  async function getFirstWaitListUser() {
    setLoading(true);
    await getPositionUserList();
    setLoading(false);
  }


  async function postEnterWaitList() {
    try {
      dispatch({ type: APP_MODAL_LOADING_ACTION, payload: true });

      const payload = {
        idUser: availableUser,
        idList: waitListIdSearch,
        byQrCode: showKnowApp,
      };

      const response = await PostToEnterWaitList(payload);
      const responseData = response?.data;
      const responseStatus = response?.status;

      if (responseStatus !== EResponseStatus.SUCCESS || responseData?.position <= 0) {
        console.error(response);
        showSnackBarProps(Colors.danger, translate(TranslateConfig.ERROR_WAIT_LISTS_ENTER));
        return;
      }

      setWaitListPosition(responseData?.position);
    }
    catch (error: any) {
      console.error(error);
      setWaitListPosition(null);

      const getMessageError = treatErrorAndReturnMessage(error);
      const messageError = translate(getMessageError);
      const colorError = treatErrorAndReturnColor(error);
      showSnackBarProps(colorError, messageError);
    }
    finally {
      dispatch({ type: APP_MODAL_LOADING_ACTION, payload: false });
    }
  };


  async function userExitWaitList() {
    setExitListModal(false);

    try {
      dispatch({ type: APP_MODAL_LOADING_ACTION, payload: true });

      const response = await PostLeaveWaitList(waitListIdSearch, availableUser);
      const responseStatus = response?.status;

      if (responseStatus !== EResponseStatus.SUCCESS) {
        console.error(response);
        showSnackBarProps(Colors.danger, translate(TranslateConfig.ERROR_WAIT_LISTS_EXIT));
        return;
      }

      showSnackBarProps(Colors.accept, translate(TranslateConfig.SUCCESS_WAIT_LISTS_EXIT));
      await getFirstWaitListUser();
    }
    catch (error: any) {
      console.error(error);

      const getMessageError = treatErrorAndReturnMessage(error);
      const messageError = translate(getMessageError);
      const colorError = treatErrorAndReturnColor(error);
      showSnackBarProps(colorError, messageError);
    }
    finally {
      dispatch({ type: APP_MODAL_LOADING_ACTION, payload: false });
    }
  }


  function renderLoading() {
    return (

      <LoadingScreen
        type={'SCREEN'}
        color={Colors.primary}
      />

    );
  }


  function userEmptyComponent() {
    return (

      <EmptyContent
        image={Images.noFavoritesCompanies}
        title={translate(TranslateConfig.EMPTY_WAIT_LIST_USER)}
      />

    );
  }


  function noInListComponent() {
    return (

      <EmptyContent
        image={Images.noFavoritesCompanies}
        title={translate(TranslateConfig.EMPTY_WAIT_LIST_ENTER_USER)}
      />

    );
  }


  function renderTextButton() {
    if (loading) {
      return translate(TranslateConfig.LOADING);
    }

    if (!availableUser) {
      return translate(TranslateConfig.ACTION_ACCESS_REGISTER);
    }

    if (availableUser && !waitListPosition) {
      return translate(TranslateConfig.ACTION_LIST_ENTER);
    }

    if (availableUser && waitListPosition && waitListObject?.userCanExit) {
      return translate(TranslateConfig.ACTION_LIST_EXIT);
    }

    if (availableUser && waitListPosition && !waitListObject?.userCanExit) {
      return translate(TranslateConfig.EMPTY_WAIT_LIST_EXIT_UNAVAILABLE);
    }

    return '-';
  }


  function renderColorButton() {
    if (loading) {
      return ArrayColors.arrayDisabled;
    }

    if (!availableUser) {
      return ArrayColors.arrayAction;
    }

    if (availableUser && !waitListPosition) {
      return ArrayColors.arrayOk;
    }

    if (availableUser && waitListPosition && waitListObject?.userCanExit) {
      return ArrayColors.arrayCancel;
    }

    if (availableUser && waitListPosition && !waitListObject?.userCanExit) {
      return ArrayColors.arrayDisabled;
    }

    return ArrayColors.arrayDisabled;
  }


  function renderPressButton() {
    if (loading) {
      return;
    }

    if (!availableUser) {
      history.push(NameRoutes.AccessScreen);
      return;
    }

    if (availableUser && !waitListPosition) {
      postEnterWaitList();
      return;
    }

    if (availableUser && waitListPosition && waitListObject?.userCanExit) {
      setExitListModal(true);
    }
  }


  function renderBackgroundPosition() {
    if (!waitListPosition) {
      return Colors.disabled;
    }

    if (waitListPosition === 1) {
      return Colors.primary;
    }

    if (waitListPosition === 2) {
      return Colors.sketch;
    }

    if (waitListPosition === 3) {
      return Colors.attentionDark;
    }

    if (waitListPosition > 3) {
      return Colors.primaryDark;
    }

    return Colors.disabled;
  }



  useEffect(() => {
    if (timeUpdate <= -1) {
      setTimeUpdate(timeLimit);
    }

    if (timeUpdate === 0) {
      getPositionUserList();
    }
  }, [timeUpdate]);


  useEffect(() => {
    if (availableUser && waitListPosition) {
      const interval = setInterval(() => {
        setTimeUpdate((prevState) => {
          return prevState - 1;
        });
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [waitListPosition, timeUpdate]);


  useEffect(() => {
    if (availableUser) {
      getFirstWaitListUser();
    }
  }, []);



  return (

    <Screen
      backgroundColor={Colors.appBackground}>

      <Container>

        {showKnowApp && statusKnowApp && (
          <KnowApp
            onPress={() => {
              history.replace(NameRoutes.GridEventsScreen);
              setStatusKnowApp(false);
            }}
            onClosePress={() => {
              setStatusKnowApp(false);
            }}
          />
        )}


        {!showKnowApp && (
          <HeaderScreen
            backgroundColor={Colors.toolbarBackground}
            subBackgroundColor={Colors.toolbarBackground}
            leftIcon={
              <ButtonBack
                color={Colors.white}
              />
            }
            centerContent={
              <Body>
                <Title
                  color={Colors.white}>
                  {translate(TranslateConfig.MENUSHEET)}
                </Title>

                <SubTitle
                  color={Colors.white}>
                  {translate(TranslateConfig.LIST_ID)}: {waitListIdSearch || '-'}
                </SubTitle>
              </Body>
            }
          />
        )}



        {loading && renderLoading()}



        {!loading && !availableUser && userEmptyComponent()}



        {!loading && availableUser && (
          <Content>

            <DetailsHeaderBlur
              image={companySelected?.picture}
              banner={companySelected?.picture}
              name={companySelected?.name}
              username={companySelected?.company_username}
            />


            {!waitListPosition && noInListComponent()}


            {waitListPosition && (
              <WaitListUserInsideView>

                <WaitListUserDateView>
                  <WaitListUserDateText>
                    {translate(TranslateConfig.LIST_FROM)}: {FormatIsoDateWithPatternDayjs(waitListObject?.forDate, 'DD/MM/YYYY') || '-'}
                  </WaitListUserDateText>


                  <WaitListUserTitle>
                    {translate(TranslateConfig.SUBTITLES_WAIT_LISTS_USER_POSITION)}
                  </WaitListUserTitle>


                  <WaitListSubtitleContainer>

                    <WaitListSubtitleText>
                      {timeUpdate > 0
                        ? `${translate(TranslateConfig.UPDATING_IN)} ${timeUpdate}s`
                        : translate(TranslateConfig.UPDATING)
                      }
                    </WaitListSubtitleText>


                    {timeUpdate <= 0 && (
                      <LoadingProgress
                        color={Colors.gray}
                        size={12}
                      />
                    )}

                  </WaitListSubtitleContainer>
                </WaitListUserDateView>


                <WaitListUserCircle
                  backgroundColor={renderBackgroundPosition()}>

                  <WaitListUserTextCircle>
                    {waitListPosition || '-'}
                  </WaitListUserTextCircle>

                </WaitListUserCircle>


                <WaitListUserExitText>
                  {waitListObject?.userCanExit
                    ? translate(TranslateConfig.SUBTITLES_WAIT_LISTS_EXIT_ON)
                    : translate(TranslateConfig.SUBTITLES_WAIT_LISTS_EXIT_OFF)
                  }
                </WaitListUserExitText>

              </WaitListUserInsideView>
            )}

          </Content>
        )}



        <FooterChoose
          hideCancelButton

          okColor={renderColorButton()}
          okPress={() => {
            renderPressButton();
          }}
          okLabel={
            <ButtonText
              color={Colors.white}>
              {renderTextButton()}
            </ButtonText>
          }
        />

      </Container>



      <AlertMessage
        visible={exitListModal}
        title={translate(TranslateConfig.QUESTION_WAIT_LISTS_USER_EXIT)}
        cancelText={translate(TranslateConfig.NO)}
        cancelPress={() => {
          setExitListModal(false);
        }}
        okText={translate(TranslateConfig.YES)}
        okPress={() => {
          userExitWaitList();
        }}
      />

    </Screen>

  );
};



export default WaitListsUserScreen;
