import styled from 'styled-components';

import { Colors } from '@noitada/shared/constants';



export const DayView = styled.div`
  display: flex;
  flex-direction: column;
`;



export const DayItem = styled.p`
  margin-bottom: 1px;
  color: ${Colors.white};
  font-size: 11px;
  text-align: center;
`;

export const DateItem = styled.p`
  color: ${Colors.white};
  font-size: 14px;
  text-align: center;
`;


export const YearItem = styled.p`
  color: ${Colors.white};
  font-size: 11px;
  letter-spacing: 1px;
  text-align: center;
`;
