import React,
{
  useState,
  useEffect,
} from 'react';

import {
  useTranslation,
} from 'react-i18next';

import {
  useDispatch,
  useSelector,
} from 'react-redux';

import {
  useHistory,
} from 'react-router-dom';

import {
  GetAllUserWaitList,
} from '@noitada/axios-config/endpoints/wait-lists.endpoints';

import {
  RootState,
} from '@noitada/redux-config';

import {
  APP_MENU_SELECTED_ACTION,
  APP_SNACKBAR_ACTION,
} from '@noitada/redux-config/reducers/app.store';

import {
  Colors,
  Images,
} from '@noitada/shared/constants';

import {
  EDrawerMenu,
} from '@noitada/shared/enums';

import EPeriodLists from '@noitada/shared/enums/Event/event-lists-period.enum';

import {
  TranslateConfig,
} from '@noitada/shared/translations';

import {
  formatIsToFractionDayjs,
} from '@noitada/shared/utils/date.utils';

import {
  treatErrorAndReturnColor,
  treatErrorAndReturnMessage,
} from '@noitada/shared/utils/errors.utils';

import CardWaitListsCompanyActive from '../../../../components/Cards/CardWaitLists/CardWaitListsCompanyActive';

import {
  Title,
  Screen,
  Body,
  ToolbarGroup,
} from '../../../../components/Composh/web';

import {
  ButtonArchived,
  ButtonDrawer,
  ButtonMore,
} from '../../../../components/Controls';

import EmptyContent from '../../../../components/Empty';
import HeaderScreen from '../../../../components/Headers/HeaderScreen';
import LoadingScreen from '../../../../components/Loadings/LoadingScreen';
import LoadingSubTitle from '../../../../components/Loadings/LoadingSubTitle';

import NameRoutes from '../../../../navigation/names';

import {
  FlatListListWaitList,
} from './styled';



const WaitListFutureScreen: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t: translate } = useTranslation();


  const user = useSelector((state: RootState) => state.user.actualUser);
  const availableUser = user?.id;


  const timeLimit = 5;
  const [timeUpdate, setTimeUpdate] = useState<number>(timeLimit);

  const [waitListArray, setWaitListArray] = useState<Array<any>>([]);

  const [loading, setLoading] = useState(false);



  function showSnackBarProps(snackColor: string, snackText: string) {
    dispatch({
      type: APP_SNACKBAR_ACTION,
      payload: {
        visible: true,
        color: snackColor,
        text: snackText,
      },
    });
  }


  async function loadWaitListsUser() {
    try {
      const response = await GetAllUserWaitList(availableUser, EPeriodLists.FUTURE);
      const responseData = response?.data;

      if (!responseData || responseData?.length <= 0) {
        console.error(response);
        setWaitListArray([]);
        return;
      }

      setWaitListArray(responseData);
    }
    catch (error: any) {
      console.error(error);
      setWaitListArray([]);

      const getMessageError = treatErrorAndReturnMessage(error);
      const messageError = translate(getMessageError);
      const colorError = treatErrorAndReturnColor(error);
      showSnackBarProps(colorError, messageError);
    }
  };


  const getReservesFunction = async () => {
    setLoading(true);
    await loadWaitListsUser();
    setLoading(false);
  };


  function renderItem(item: any, index: number) {
    return (

      <CardWaitListsCompanyActive
        key={index}

        position={item?.position}
        image={item?.waitListCompany?.picture}
        name={item?.waitListCompany?.name}
        description={item?.waitListCompany?.company_username
          ? `@${item?.waitListCompany?.company_username}`
          : '-'
        }
        partner={item?.waitListCompany?.partner}
        verified={item?.waitListCompany?.verified}
        time={formatIsToFractionDayjs(item?.createdAt)}
        observations={item?.observations}
        priority={item?.priority}
        status={item?.status}

        onPress={() => {
          history.push(NameRoutes.WaitListsUserScreen.replace(
            ':id_wait_list',
            item?.waitList?.idShort,
          ));
        }}
      />

    );
  };


  function renderLoading() {
    return (

      <LoadingScreen
        type={'SCREEN'}
        color={Colors.primary}
      />

    );
  }


  function listEmptyComponent() {
    return (

      <EmptyContent
        image={Images.noReserveUpcoming}
        title={translate(TranslateConfig.EMPTY_WAIT_LIST_AVAILABLE)}
      />

    );
  }



  useEffect(() => {
    if (timeUpdate <= -1) {
      setTimeUpdate(timeLimit);
    }

    if (timeUpdate === 0) {
      loadWaitListsUser();
    }
  }, [timeUpdate]);


  useEffect(() => {
    const interval = setInterval(() => {
      setTimeUpdate((prevState) => {
        return prevState - 1;
      });
    }, 1000);
    return () => clearInterval(interval);
  }, [timeUpdate]);


  useEffect(() => {
    dispatch({ type: APP_MENU_SELECTED_ACTION, payload: EDrawerMenu.WAIT_LISTS });
    getReservesFunction();
  }, []);



  return (

    <Screen
      backgroundColor={Colors.appBackground}>

      <HeaderScreen
        backgroundColor={Colors.toolbarBackground}
        subBackgroundColor={Colors.toolbarBackground}
        leftIcon={
          <ButtonDrawer
            color={Colors.white}
          />
        }
        centerContent={
          <Body>
            <Title
              color={Colors.white}>
              {translate(TranslateConfig.WAIT_LISTS)}
            </Title>

            <LoadingSubTitle
              loading={timeUpdate <= 0}
              time={timeUpdate}
            />
          </Body>
        }
        rightIcon={
          <ToolbarGroup>

            <ButtonArchived
              color={Colors.white}
              onPress={() => {
                history.push(NameRoutes.WaitListPastScreen);
              }}
            />

            <ButtonMore
              color={Colors.white}
            />

          </ToolbarGroup>
        }
      />



      <FlatListListWaitList
        loading={loading}
        data={waitListArray}
        renderItem={renderItem}
        listLoadingComponent={renderLoading()}
        listEmptyComponent={listEmptyComponent()}
      />

    </Screen>

  );
};



export default WaitListFutureScreen;
