import React,
{
  useState,
} from 'react';

import {
  useTranslation,
} from 'react-i18next';

import {
  useDispatch,
  useSelector,
} from 'react-redux';

import {
  useHistory,
} from 'react-router-dom';

import {
  GetUserCurrentCheckin,
} from '@noitada/axios-config/endpoints/order-sheet-checkin.endpoints';

import {
  AddItem,
} from '@noitada/axios-config/endpoints/order-sheet.endpoints';

import {
  RootState,
} from '@noitada/redux-config';

import {
  APP_MODAL_LOADING_ACTION,
  APP_SNACKBAR_ACTION,
} from '@noitada/redux-config/reducers/app.store';

import {
  USER_SET_CHECKIN,
} from '@noitada/redux-config/reducers/comanda.store';

import {
  USER_CLEAR_SACOLA,
  USER_REMOVE_SACOLA_ITEM,
  USER_UPDATE_SACOLA_ITEM,
} from '@noitada/redux-config/reducers/order-bag.store';

import {
  Colors,
  Images,
  Sizes,
} from '@noitada/shared/constants';

import {
  TranslateConfig,
} from '@noitada/shared/translations';

import ButtonSeeMore from '../../../../components/Buttons/ButtonSeeMore';
import CardOrderBagBuy from '../../../../components/Cards/CardOrders/CardOrderBagBuy';

import {
  Screen,
  Title,
  Container,
  Content,
} from '../../../../components/Composh/web';

import {
  ButtonBack,
} from '../../../../components/Controls';

import FooterAction from '../../../../components/Footers/FooterAction';
import HeaderScreen from '../../../../components/Headers/HeaderScreen';

import {
  IconDelete,
  IconOrderSheet,
} from '../../../../components/Icons';

import {
  FormatMoney,
} from '../../../../config/mask.config';

import ModalOk from '../../../../modals/ModalOk';

import NameRoutes from '../../../../navigation/names';

import {
  CleanBagButton,
  HeaderBagView,
  BagPlaceView,
  BagPlaceImage,
  BagInfoPlaceView,
  BagInfoPlaceNameText,
  BagPlaceMinOrderView,
  BagPlaceMinOrderText,
  BagTotalTitle,
  BagTotalText,
} from './styled';



const OrderBagScreen: React.FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { t: translate } = useTranslation();


  const company = useSelector((state: RootState) => state.company.selected);
  const checkinUser = useSelector((state: RootState) => state.comanda.checkin);
  const sacola = useSelector((state: RootState) => state.sacola);


  const [modalOk, setModalOk] = useState(false);



  function showSnackBarProps(snackColor: string, snackText: string) {
    dispatch({
      type: APP_SNACKBAR_ACTION,
      payload: {
        visible: true,
        color: snackColor,
        text: snackText,
      },
    });
  }


  async function doOrderSheet() {
    dispatch({ type: APP_MODAL_LOADING_ACTION, payload: true });

    const items: any[] = [];
    for (let i = 0; i < sacola.items.length; i++) {
      const item = sacola.items[i];

      const payloadItem = {
        itemSaleId: item?.itemSale?.id,
        itemGroupId: item?.itemGroup.id,
        observations: '',
        comments: item?.itemSale?.comments || '',
        quantity: item?.quantity,
      };

      items.push(payloadItem);
    }

    try {
      await AddItem({ items });

      const checkinData = await GetUserCurrentCheckin();

      dispatch({ type: USER_SET_CHECKIN, payload: checkinData });
      dispatch({ type: USER_CLEAR_SACOLA });

      setModalOk(true);
    }
    catch (error: any) {
      console.error(error);

      const message = error?.hasError && error?.message && typeof error?.message === 'string'
        ? error?.message
        : translate(TranslateConfig.ERROR_HAD);
      showSnackBarProps(Colors.danger, message);
    }
    finally {
      dispatch({ type: APP_MODAL_LOADING_ACTION, payload: false });
    }
  }


  async function updateItem(item: any, index: number, value: any) {
    const copied = { ...item };

    if (value === -1) {
      if (copied.quantity === 1) {
        const confirm_remove = confirm(translate(TranslateConfig.QUESTION_BAG_ITEM_REMOVE).replace('{itemSaleName}', item?.itemSale?.name));
        if (confirm_remove) dispatch({ type: USER_REMOVE_SACOLA_ITEM, payload: { idx: index } });

        return;
      }
      else {
        copied.quantity--;
      }
    }
    else if (value === 1) {
      copied.quantity++;
    }

    dispatch({ type: USER_UPDATE_SACOLA_ITEM, payload: { idx: index, item: copied } });
  }


  async function clearBag() {
    // FIXME: Transformar em modal
    const confirmClear = confirm(translate(TranslateConfig.QUESTION_BAG_CLEAR));
    if (!confirmClear) {
      return;
    }

    dispatch({ type: USER_CLEAR_SACOLA });
  }


  function renderItem(item: any, index: number) {
    return (

      <CardOrderBagBuy
        title={item?.itemSale?.name}
        titleGroup={item?.itemGroup?.name}
        description={item?.itemSale?.description}
        image={item?.itemSale?.picture}
        comments={item?.itemSale?.comments}
        quantity={item?.quantity}
        price={FormatMoney(item?.itemGroup.price * item?.quantity)}
        updateQuantity={(value: any) => {
          updateItem(item, index, value);
        }}
      />

    );
  }



  return (

    <Screen
      backgroundColor={Colors.appBackground}>

      <Container>

        <HeaderScreen
          backgroundColor={Colors.toolbarBackground}
          subBackgroundColor={Colors.toolbarBackground}
          leftIcon={
            <ButtonBack
              close
              color={Colors.white}
            />
          }
          centerContent={
            <Title>
              {translate(TranslateConfig.ORDERBAG)}
            </Title>
          }
          rightIcon={
            <CleanBagButton>
              <BagTotalTitle>
                {translate(TranslateConfig.TOTAL)}
              </BagTotalTitle>


              <BagTotalText>
                {FormatMoney(checkinUser?.totalConsumed)}
              </BagTotalText>
            </CleanBagButton>
          }
        />



        <Content>

          <HeaderBagView>

            <BagPlaceView>
              <BagPlaceImage
                alt={'cover'}
                src={company.picture || Images.placeholderAvatarCompany}
              />


              <BagInfoPlaceView>
                <BagInfoPlaceNameText>
                  {checkinUser?.company?.name}
                </BagInfoPlaceNameText>


              </BagInfoPlaceView>

            </BagPlaceView>



            {sacola.items.map((item: any, index: number) => (
              renderItem(item, index)
            ))}



            {false && (
              // TODO
              <BagPlaceMinOrderView>
                <BagPlaceMinOrderText>
                  {translate(TranslateConfig.MIN_ORDERSHEET)}: R$ 0,00
                </BagPlaceMinOrderText>
              </BagPlaceMinOrderView>
            )}

          </HeaderBagView>



          <ButtonSeeMore
            title={translate(TranslateConfig.ACTION_ADD_MORE_ITEMS)}
            onPress={() => {
              // navigation.dispatch(StackActions.push(NameRoutes.MenuSheetScreen, {
              //   company: checkinUser?.company,
              // }));
              history.push({
                pathname: NameRoutes.MenuSheetScreen.replace(
                  ':company_username',
                  checkinUser?.company.company_username,
                ),
              });
            }}
          />

        </Content>



        <FooterAction
          buttonIcon={
            <IconDelete
              color={Colors.white}
              size={20}
            />
          }
          buttonLabel={translate(TranslateConfig.ACTION_BAG_CLEAN)}
          buttonPress={() => {
            clearBag();
          }}

          actionDisabled={sacola?.items?.length <= 0}
          actionIcon={
            <IconOrderSheet
              color={Colors.white}
              size={Sizes.iconAndroidSize}
            />
          }
          actionLabel={translate(TranslateConfig.ACTION_FINISH_ORDER)}
          actionPress={() => {
            doOrderSheet();
          }}
        />

      </Container>



      <ModalOk
        visible={modalOk}
        backgroundColor={Colors.cardBackground}
        image={Images.orderDone}
        title={translate(TranslateConfig.SUCCESS_ORDER_DONE)}
        description={translate(TranslateConfig.HELP_SUCCESS_ORDER_DONE)}
        buttonText={translate(TranslateConfig.ACTION_CLOSE)}
        onPress={() => {
          history.goBack();
        }}
      />

    </Screen>

  );
};



export default OrderBagScreen;
