import React,
{
  useState,
} from 'react';

import {
  useTranslation,
} from 'react-i18next';

import {
  Colors,
  Sizes,
} from '@noitada/shared/constants';

import {
  TranslateConfig,
} from '@noitada/shared/translations';

import {
  Items,
  Epigraph,
  Input,
} from '../../../../../../components/Composh/web';

import {
  IconTypeNightParty,
  IconTypeParty,
} from '../../../../../../components/Icons';

import {
  DetailsSectionView,
} from '../../../../../../styles/styled.layout';

import {
  EventTypeContent,
} from '../styled';



export interface IProps {
  loading?: boolean;
  setFieldValue: any;
}



const SectionTypeEvent: React.FC<IProps> = (props) => {
  const { t: translate } = useTranslation();


  const loading = props.loading;
  const setFieldValue = props.setFieldValue;

  const [type, setType] = useState('');



  function setEventType(type: string) {
    setType(type);
    setFieldValue('type', type);
  }



  return (

    <DetailsSectionView>

      <Epigraph.Section
        text={`${translate(TranslateConfig.EVENT_TYPE)} *`}
        textColor={Colors.primary}
        borderBottomColor={Colors.primary}
      />


      <Input.View
        noShadow
        backgroundColor={Colors.transparent}
        countColor={Colors.textDescription}
        countLimit={translate(TranslateConfig.VALIDATE_ONLY_ONE)}>

        <EventTypeContent>

          <Items.Grid
            disabled={loading}
            activeOpacity={Sizes.ButtonActiveOpacity}
            icon={
              <IconTypeParty
                color={
                  type === 'PARTY'
                    ? Colors.accent
                    : Colors.textApp}
                size={35}
              />
            }
            iconGridLabel={translate(TranslateConfig.PARTY)}
            iconGridColor={
              type === 'PARTY'
                ? Colors.accent
                : Colors.textApp
            }
            onPress={() => {
              setEventType('PARTY');
            }}
          />


          <Items.Grid
            disabled={loading}
            activeOpacity={Sizes.ButtonActiveOpacity}
            icon={
              <IconTypeNightParty
                color={
                  type === 'NIGHT_PARTY'
                    ? Colors.accent
                    : Colors.textApp}
                size={35}
              />
            }
            iconGridLabel={translate(TranslateConfig.NIGHT_PARTY)}
            iconGridColor={
              type === 'NIGHT_PARTY'
                ? Colors.accent
                : Colors.textApp
            }
            onPress={() => {
              setEventType('NIGHT_PARTY');
            }}
          />

        </EventTypeContent>
      </Input.View>

    </DetailsSectionView>

  );
};



export default SectionTypeEvent;
