import React,
{
  useState,
  useEffect,
} from 'react';

import {
  useTranslation,
} from 'react-i18next';

import {
  useDispatch,
} from 'react-redux';

import {
  GetDatesGroupReserves,
} from '@noitada/axios-config/endpoints/entrance-list.endpoints';

import {
  APP_SNACKBAR_ACTION,
} from '@noitada/redux-config/reducers/app.store';

import {
  Colors,
  Images,
} from '@noitada/shared/constants';

import EPeriodLists from '@noitada/shared/enums/Event/event-lists-period.enum';

import {
  TranslateConfig,
} from '@noitada/shared/translations';

import CardListUser from '../../../../../components/Cards/CardListUser';
import EmptyContent from '../../../../../components/Empty';
import LoadingScreen from '../../../../../components/Loadings/LoadingScreen';

import {
  ListTicketContentFlex,
} from './styled';



export interface IProps {
  openParticipate?: any;
}



const TabListsUpcomming: React.FC<IProps> = (props: IProps) => {
  const dispatch = useDispatch();
  const { t: translate } = useTranslation();



  const [loading, setLoading] = useState(false);

  const [userLists, setUserLists] = useState<Array<any>>([]);



  function showSnackBarProps(snackColor: string, snackText: string) {
    dispatch({
      type: APP_SNACKBAR_ACTION,
      payload: {
        visible: true,
        color: snackColor,
        text: snackText,
      },
    });
  }


  const getReservesFunction = async () => {
    try {
      setLoading(true);

      const response = await GetDatesGroupReserves(EPeriodLists.FUTURE);

      if (response) {
        setUserLists(response);
      }
    }
    catch (error: any) {
      console.error(error);

      const message = error?.hasError && error?.message && typeof error?.message === 'string'
        ? error?.message
        : translate(TranslateConfig.ERROR_HAD);
      showSnackBarProps(Colors.danger, message);
    }
    finally {
      setLoading(false);
    }
  };


  function renderItem(item: any, index: number) {
    return (

      <CardListUser
        key={index}
        date={item?.event?.startAt}
        companyImage={item?.company_picture}
        companyName={item?.event?.company?.name}
        listId={item?.shortId}
        listName={item?.name}
        eventImage={item?.eventPicture}
        eventName={item?.event?.name}
        eventType={item?.event?.type}
        city={item?.event?.address?.city}
        state={item?.event?.address?.state}
        onPress={() => {
          props.openParticipate(item);
        }}
      />

    );
  }


  function renderLoading() {
    return (

      <LoadingScreen
        type={'SCREEN'}
        color={Colors.primary}
      />

    );
  }


  function listEmptyComponent() {
    return (

      <EmptyContent
        image={Images.listEventWait}
        title={translate(TranslateConfig.EMPTY_LISTS_FINISHED)}
      />

    );
  }



  useEffect(() => {
    getReservesFunction();
  }, []);



  return (

    <ListTicketContentFlex
      loading={loading}
      data={userLists}
      renderItem={renderItem}
      listLoadingComponent={renderLoading()}
      listEmptyComponent={listEmptyComponent()}
    />

  );
};



export default TabListsUpcomming;
