import NoitadaWS from '../index';



export const GetAllUserWaitList = async (idUser: string, period: string) => {
  const url = `/app/wait-lists/user-list/details/${period}`;

  const payload = {
    id: idUser,
  };

  const response = await NoitadaWS.post(url, payload);
  return response.data;
};



export const GetUserInWaitList = async (idShortWaitList: string, idUser: string) => {
  const url = `/app/wait-lists/${idShortWaitList}/${idUser}`;

  const response = await NoitadaWS.get(url);
  return response.data;
};



export const PostToEnterWaitList = async (payload: any) => {
  const url = '/app/wait-lists/user-enter-list';

  const response = await NoitadaWS.post(url, payload);
  return response.data;
};



export const PostLeaveWaitList = async (idShortWaitList: string, idUser: string) => {
  const url = `/app/wait-lists/${idShortWaitList}/${idUser}`;

  const response = await NoitadaWS.delete(url);
  return response.data;
};
