import React from 'react';

import {
  Epigraph,
} from '../../Composh/web';

import {
  MenuEpigraphSection,
} from './styled';



export interface IProps {
  title: string;
  color?: string;
}



const DrawerTitle: React.FC<IProps> = (props) => {
  const colorTitle = props.color;

  return (

    <MenuEpigraphSection>

      <Epigraph.Section
        text={props.title}
        textColor={colorTitle}
        borderBottomColor={colorTitle}
      />

    </MenuEpigraphSection>

  );
};



export default DrawerTitle;
