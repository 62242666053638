import React,
{
  useEffect,
  useState,
} from 'react';

import {
  useTranslation,
} from 'react-i18next';

import {
  useDispatch,
  useSelector,
} from 'react-redux';

import {
  RootState,
} from '@noitada/redux-config';

import {
  APP_MENU_SELECTED_ACTION,
} from '@noitada/redux-config/reducers/app.store';

import {
  Colors,
} from '@noitada/shared/constants';

import {
  EDrawerMenu,
} from '@noitada/shared/enums';

import {
  TranslateConfig,
} from '@noitada/shared/translations';

import {
  Title,
  Screen,
  Container,
} from '../../../../components/Composh/web';

import {
  ButtonDrawer,
  ButtonMore,
} from '../../../../components/Controls';

import HeaderScreen from '../../../../components/Headers/HeaderScreen';

import TabBarStyled from '../../../../components/TabBar';

import ListParticipateScreen from '../CompanyLists/Participate';

import TabListsPast from './Tabs/TabPast';
import TabListsUpcomming from './Tabs/TabUpcomming';



const UserListsScreen: React.FC = () => {
  const dispatch = useDispatch();
  const { t: translate } = useTranslation();


  const user = useSelector((state: RootState) => state.user.actualUser);


  const [openParticipate, setOpenParticipate] = useState<any | null>(null);

  const [stateIndex, setStateIndex] = useState(0);


  const screenTabs = [
    {
      name: 'ListsUpcomming',
      title: translate(TranslateConfig.NEXT),
      component: (
        <TabListsUpcomming
          openParticipate={(itemReceived: any) => {
            setOpenParticipate(itemReceived);
          }}
        />
      ),
    },
    {
      name: 'ListsPast',
      title: translate(TranslateConfig.PAST),
      component: <TabListsPast />,
    },
  ];



  useEffect(() => {
    dispatch({ type: APP_MENU_SELECTED_ACTION, payload: EDrawerMenu.LISTS_PARTICIPATE });
  }, []);



  return (

    <Screen
      backgroundColor={Colors.appBackground}>

      <Container>

        <HeaderScreen
          backgroundColor={Colors.toolbarBackground}
          subBackgroundColor={Colors.toolbarBackground}
          leftIcon={
            <ButtonDrawer
              color={Colors.white}
            />
          }
          centerContent={
            <Title
              color={Colors.white}>
              {translate(TranslateConfig.LIST_PARTICIPATE)}
            </Title>
          }
          rightIcon={
            <ButtonMore
              color={Colors.white}
            />
          }
          noSubToolbarPadding>


          <TabBarStyled
            tabs={screenTabs}
            selected={stateIndex}
            onPress={(tabIndex: number) => {
              setStateIndex(tabIndex);
            }}
          />

        </HeaderScreen>



        {stateIndex === 0 && screenTabs[0].component}

        {stateIndex === 1 && screenTabs[1].component}


      </Container>



      <ListParticipateScreen
        visible={openParticipate}
        list={openParticipate}
        editable={true}
        idUser={user?.id}
        canInvite={openParticipate?.canInvite && openParticipate?.additionalGuestsNumber > openParticipate?.guests}
        inList={openParticipate?.inList}
        onCancelPress={() => {
          setOpenParticipate(null);
        }}
      />

    </Screen>

  );
};



export default UserListsScreen;
