import styled,
{
  css,
} from 'styled-components';

import {
  Colors,
  Sizes,
} from '@noitada/shared/constants';

import {
  PlatformDevices,
} from '@noitada/shared/utils/platforms.utils';

import {
  ListFlat,
} from '../../../components/Composh/web';

import {
  GetPlatform,
} from '../../../config/plataform.config';

const paddingHighlight = Sizes.paddingFlatList + 'px';



export const ScrollbarHideStyle = css`
  ::-webkit-scrollbar {
    display: none;
  }
`;



export const HighlightGridHeader = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-left: 15px;
  margin-right: 6px;
`;

export const HighlightTextsContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  margin-top: -10px;
`;

export const HighlightTitleToday = styled.h1`
  margin-bottom: -0.5px;
  color: white;
  font-size: 20.5px;
  font-weight: bold;
`;

export const HighlightDate = styled.p`
  color: ${Colors.textTitle};
  font-size: 11.5px;
`;


// Highlights Tab Style

export const HomeSectionView = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 5px;
`;

export const TitleHomeView = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 15px;
  margin-bottom: 10px;
  padding-left: calc(${paddingHighlight} + 2px);
  padding-right: calc(${paddingHighlight} + 2px);
`;

export const TitleHomeEvents = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 15px;
  margin-bottom: 5px;
  padding-left: calc(${paddingHighlight} - 10px);
  padding-right: calc(${paddingHighlight} - 10px);
`;

export const ScrollContentFlex = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 25px;
`;



export const ScrollbarHorizontalStyle = css`
  ::-webkit-scrollbar {
    height: 17px;
  }

  ::-webkit-scrollbar-track,
  ::-webkit-scrollbar {
    background: ${Colors.appBackground};
  }

  ::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 10px 10px ${Colors.appScrollbar};

    border-color: ${Colors.appBackground};
    border-style: solid;
    border-top-width: 9px;
    border-left-width: 15px;
    border-right-width: 15px;
    border-bottom-width: 1px;
  }
`;

export const HightlightsScrollHorizontalItems = styled.div`
  display: flex;
  flex-direction: row;
  overflow-x: auto;
  overflow-y: hidden;
  padding-left: calc(${paddingHighlight} - 2px);
  padding-right: calc(${paddingHighlight} - 2px);

  ${GetPlatform() === 'WEB' ? ScrollbarHorizontalStyle : ScrollbarHideStyle}
`;

export const HighlightEventScrollFlatList = styled(ListFlat).attrs({
  style: {
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: paddingHighlight,
    paddingRight: paddingHighlight,
  },
  contentContainerStyle: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
})`
  display: flex;
  flex-direction: column;
  flex: 1;
`;


// Event Tab Style

export const TitleEventView = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 15px;
  margin-bottom: 10px;

  @media ${PlatformDevices.minLaptop} {
    padding-left: calc(${paddingHighlight} - 8px);
  }
`;

export const EventScrollFlatList = styled(ListFlat).attrs({
  contentContainerStyle: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    paddingLeft: paddingHighlight,
    paddingRight: paddingHighlight,
    paddingBottom: 20,
  },
})`
  display: flex;
  flex-direction: column;
  flex: 1;
`;


// Profile Tab Style

export const ScrollbarProfileStyle = css`
  margin-top: -4px;
  padding-bottom: 0;

  ::-webkit-scrollbar {
    height: 9px;
  }

  ::-webkit-scrollbar-track,
  ::-webkit-scrollbar {
    background: ${Colors.toolbarBackground};
  }

  ::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 10px 10px ${Colors.appScrollbar};

    border-color: ${Colors.toolbarBackground};
    border-style: solid;
    border-top-width: 1px;
    border-left-width: 10px;
    border-right-width: 10px;
    border-bottom-width: 4px;
  }
`;

export const ProfileScrollItemContent = styled.div`
  display: flex;
  flex-direction: row;
  overflow-x: auto;
  overflow-y: hidden;
  width: 100%;
  padding-left: 6px;
  padding-right: 6px;
  padding-bottom: 6px;

  ${GetPlatform() === 'WEB' ? ScrollbarProfileStyle : ScrollbarHideStyle}
`;

export const ProfileScrollCardsContent = styled(ListFlat).attrs({
  contentContainerStyle: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    paddingTop: paddingHighlight,
    paddingLeft: paddingHighlight,
    paddingRight: paddingHighlight,
    paddingBottom: 20,
  },
})`
  display: flex;
  flex-direction: column;
  flex: 1;
`;


// Map Tab Style

export const MapContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  flex: 1;
  z-index: 0;
`;

export const MapRefreshButton = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 12px;
  left: 8px;
  width: 40px;
  height: 40px;
  background-color: ${Colors.white};
  border-radius: 50px;
`;
