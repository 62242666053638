import React,
{
  useEffect,
  useState,
} from 'react';

import {
  useTranslation,
} from 'react-i18next';

import {
  useDispatch,
} from 'react-redux';

import {
  useSpring,
  animated,
} from '@react-spring/web';

import {
  CARNIVAL_ACTIONSHEET_CIRCUITS,
} from '@noitada/redux-config/reducers/carnival-festivals.store';

import {
  MODAL_LOCATION_APP_ACTION,
  USER_LANGUAGE,
  USER_LANGUAGE_MODAL,
  USER_LOCATION,
} from '@noitada/redux-config/reducers/localities.store';

import {
  Colors,
  Images,
} from '@noitada/shared/constants';

import {
  TintColor,
  TintSolver,
  hexToRgb,
} from '@noitada/shared/utils/ColorUtils';

import {
  Screen,
} from '../../components/Composh/web';

import {
  getSystemCurrentLanguage,
  setCurrentLanguage,
} from '../../config/language.config';

import {
  AbsoluteFillView,
  ContainerGlue,
  BackgroundColorView,
  FlexContent,
  ImageBackgroundStyle,
  LogoStyle,
  LogoImageStyle,
} from './styled';


const AnimatedBackgroundColorView = animated(BackgroundColorView);
const AnimatedFlexContent = animated(FlexContent);
const AnimatedImageBackgroundStyle = animated(ImageBackgroundStyle);
const AnimatedImageLogo = animated(LogoImageStyle);



export interface IProps {
  children?: any;
}



const AnimatedSplash: React.FC<IProps> = (props: IProps) => {
  const dispatch = useDispatch();
  const { pathname } = location;
  const { i18n } = useTranslation();


  const [animationDone, setAnimationDone] = useState(false);

  const [isLoaded, setIsLoaded] = useState(false);

  const logoWidth = 120;
  const logoHeight = 120;
  const backgroundColor = Colors.primaryDark;
  const logoImage = Images.logoIconAppWhite;
  const imageBackgroundSource = Images.backgroundSplashBubble;



  const loadingProgress = useSpring({
    from: { value: 0 },
    // to: { value:100 }, // FIXME
    to: { value: isLoaded ? 100 : 0 },
  });


  const opacityClearToVisible = useSpring({
    opacity: loadingProgress.value.to({
      range: [0, 15, 30],
      output: [0, 0, 1],
      extrapolate: 'clamp',
    }),
  });


  const imageScale = useSpring({
    transform: loadingProgress.value.to({
      range: [0, 10, 100],
      output: [1, 1, 65],
    }).to((scaleValue) => `scale(${scaleValue})`),
  });


  const logoScale = useSpring({
    transform: loadingProgress.value.to({
      range: [0, 10, 100],
      output: [1, 0.8, 10],
    }).to((scaleValue) => `scale(${scaleValue})`),
  });


  const logoOpacity = useSpring({
    opacity: loadingProgress.value.to({
      range: [0, 20, 100],
      output: [1, 0, 0],
      extrapolate: 'clamp',
    }),
  });


  const appScale = useSpring({
    transform: loadingProgress.value.to({
      range: [0, 7, 100],
      output: [1.1, 1.05, 1],
    }).to((scaleValue) => `scale(${scaleValue})`),
  });



  function tintBlackColor() {
    const rgb = hexToRgb(backgroundColor);
    const color = new TintColor(rgb?.[0], rgb?.[1], rgb?.[2]);
    const solver = new TintSolver(color);
    const result = solver.solve();
    const filterCSS = result.filter;

    return filterCSS;
  }


  function renderChildren() {
    if (isLoaded) {
      return props.children;
    }

    return null;
  }


  function setSplashLanguage() {
    const systemLang = getSystemCurrentLanguage();
    setCurrentLanguage(systemLang);
    i18n.changeLanguage(systemLang);
    dispatch({ type: USER_LANGUAGE, payload: systemLang });
  }


  function setSplashLocation() {
    dispatch({
      type: USER_LOCATION,
      payload: {
        city: 'Salvador',
        state: 'Bahia',
        country: 'Brasil',
      },
    });
  }


  function resetModalStates() {
    // TODO
  }



  useEffect(() => {
    dispatch({ type: USER_LANGUAGE_MODAL, payload: false });
    dispatch({ type: MODAL_LOCATION_APP_ACTION, payload: false });
    dispatch({ type: CARNIVAL_ACTIONSHEET_CIRCUITS, payload: false });

    if (isLoaded) {
      setSplashLanguage();
      setSplashLocation();
      resetModalStates();
    }

    setTimeout(async () => {
      setIsLoaded(true);
    }, 2000);


    if (isLoaded) {
      setAnimationDone(true);
    }
  }, [isLoaded]);



  return (

    <Screen
      style={pathname === '/privacy' ? { overflow: 'scroll' } : {}}
      backgroundColor={Colors.transparent}>

      {!animationDone && (
        <AbsoluteFillView />
      )}



      <ContainerGlue>

        {!animationDone && (
          <AnimatedBackgroundColorView
            backgroundColor={backgroundColor}
            style={{
              opacity: logoOpacity.opacity,
            }}
          />
        )}



        <AnimatedFlexContent
          style={{
            transform: isLoaded ? null : appScale.transform,
            opacity: opacityClearToVisible.opacity,
          }}>
          {renderChildren()}
        </AnimatedFlexContent>



        {!animationDone && (
          <AnimatedImageBackgroundStyle
            alt={'imageBackground'}
            src={imageBackgroundSource}
            tintColor={tintBlackColor()}
            style={{
              transform: imageScale.transform,
              opacity: logoOpacity.opacity,
            }}
          />
        )}



        {!animationDone && (
          <LogoStyle>
            <AnimatedImageLogo
              alt={'logo'}
              src={logoImage}
              width={logoWidth}
              height={logoHeight}
              style={{
                transform: logoScale.transform,
                opacity: logoOpacity.opacity,
              }}
            />
          </LogoStyle>
        )}

      </ContainerGlue>

    </Screen>

  );
};



export default AnimatedSplash;
