import React from 'react';

import {
  useTranslation,
} from 'react-i18next';

import {
  useDispatch,
  useSelector,
} from 'react-redux';

import {
  useHistory,
} from 'react-router-dom';

import {
  RootState,
} from '@noitada/redux-config';

import {
  USER_SET_CURRENT_COMPANY,
} from '@noitada/redux-config/reducers/company.store';

import {
  Colors,
  Images,
} from '@noitada/shared/constants';

import {
  TranslateConfig,
} from '@noitada/shared/translations';

import {
  formatNumber,
} from '@noitada/shared/utils/string.utils';

import DetailsAddress from '../../../../components/Address';

import {
  Container,
  Content,
  Title,
} from '../../../../components/Composh/web';

import FooterAction from '../../../../components/Footers/FooterAction';

import {
  IconDollar,
  IconList,
} from '../../../../components/Icons';

import ItemsList from '../../../../components/Items/ItemsList';
import PictureEventActived from '../../../../components/Pictures/PictureEventActived';

import {
  renderTypeIconEvent,
  renderTypeTextEvent,
} from '../../../../config/icon-types-event.config';

import NameRoutes from '../../../../navigation/names';

import {
  SubtitleDetails,
  ContentPadder,
} from '../../../../styles/styled.layout';

import DetailsAbout from './Sections/DetailsAbout';
import DetailsAttractions from './Sections/DetailsAttractions';
import DetailsAvailables from './Sections/DetailsAvailables';
import DetailsCalendar from './Sections/DetailsCalendar';
import DetailsCardCompany from './Sections/DetailsCardCompany';
import DetailsComments from './Sections/DetailsComments';
import DetailsMusicGenre from './Sections/DetailsMusicGenre';
import DetailsOpenBar from './Sections/DetailsOpenBar';
import DetailsParentalGuidelines from './Sections/DetailsParentalGuidelines';
import DetailsSeeLists from './Sections/DetailsSeePrices/SeeLists';
import DetailsSeeTickets from './Sections/DetailsSeePrices/SeeTickets';

import {
  PrideDetailsImage,
  DisableOrderSheetText,
  SeeCountEventText,
} from './styled';



export interface IProps {
  event?: any;
}



const ActiveEventScreen: React.FC<IProps> = (props: IProps) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t: translate } = useTranslation();


  const event = props.event;

  const user = useSelector((state: RootState) => state.user.actualUser);
  const availableUser = user?.id;



  function renderItemList(
    icon: any,
    title: string | null,
    titleColor: string,
    subtitle?: any,
    subtitleColor?: string,
  ) {
    return (

      <ItemsList
        iconLeft={icon}
        title={title}
        titleColor={titleColor}
        subtitle={subtitle || ''}
        subtitleColor={subtitleColor}
      />

    );
  }


  function openLoginScreen() {
    history.push(NameRoutes.AccessScreen);
  }


  function openTicketsScreen() {
    history.push({
      pathname: NameRoutes.BuyEntrancesScreen.replace(
        ':event_id',
        event?.idShort,
      ),
    });
  };


  function openListsScreen() {
    history.push({
      pathname: NameRoutes.JoinListsScreen.replace(
        ':event_id',
        event?.idShort,
      ),
    });
  };


  function paidEvent() {
    return true;
  }


  function haveTickets() {
    if (!event?.event_tickets) {
      return false;
    }

    return !!event?.event_tickets[0];
  }


  function haveList() {
    if (!event?.event_lists) {
      return false;
    }

    return !!event?.event_lists[0];
  }


  function eventPrices() {
    if (!event?.event_tickets) {
      return false;
    }

    const eventsTicket = event?.event_tickets as any;

    return eventsTicket?.map((ticket) => ticket?.lots?.map((lot) => lot?.price)).flat();
  }



  return (

    <Container>

      <Content>

        <PictureEventActived
          image={event?.picture}
        />



        <ContentPadder>

          <Title
            color={Colors.white}>
            {event?.name}
          </Title>



          <DetailsCalendar
            startTime={event?.startAt}
            endTime={event?.endAt}
          />



          {renderItemList(
            renderTypeIconEvent(event?.type,
              Colors.white,
              35,
            ),
            translate(TranslateConfig.EVENT_TYPE),
            Colors.textSubDescription,
            <SubtitleDetails>
              {translate(renderTypeTextEvent(event?.type))}
            </SubtitleDetails>,
            Colors.white,
          )}



          {haveList() && (
            <DetailsSeeLists
              // disabled={!haveTickets()}
              // paidEvent={paidEvent()}
              // haveTickets={haveTickets()}
              onPress={() => {
                if (!availableUser) {
                  openLoginScreen();
                  return;
                }

                openListsScreen();
              }}
            />
          )}



          <DetailsSeeTickets
            disabled={!haveTickets()}
            proType={event?.company?.company_plan?.type}
            paidEvent={paidEvent()}
            haveTickets={haveTickets()}
            eventPrices={eventPrices()}
            onPress={() => {
              openTicketsScreen();
            }}
          />



          <DisableOrderSheetText>
            {!event?.disableOrderSheet
              ? translate(TranslateConfig.HELP_ORDERSHEET_DETAILS_DISABLED)
              : translate(TranslateConfig.HELP_ORDERSHEET_DETAILS_ENABLED)
            }
          </DisableOrderSheetText>

        </ContentPadder>



        {event?.lgbt && renderItemList(
          <PrideDetailsImage
            alt={'pride'}
            src={Images.flagPrideColor}
          />,
          translate(TranslateConfig.EVENT_IDEAL_LGBT),
          Colors.white,
        )}



        <DetailsAddress
          companyName={event?.name}
          address={event?.address}
        />



        <ContentPadder>

          <DetailsAvailables
            availables={event?.availables}
          />



          {event?.about && (
            <DetailsAbout
              about={event?.about}
            />
          )}



          {event?.attractions && (
            <DetailsAttractions
              attractions={event?.attractions}
            />
          )}



          <DetailsParentalGuidelines
            parentalRating={event?.parentalRating}
          />



          <DetailsMusicGenre
            musicGenre={event?.musicGenre}
          />



          <DetailsOpenBar
            openBar={event?.drinks}
          />



          <DetailsComments
            comments={event?.comments}
          />



          <DetailsCardCompany
            name={event?.company?.name}
            image={event?.company?.picture}
            onPress={() => {
              dispatch({ type: USER_SET_CURRENT_COMPANY, payload: event?.company });
              history.push({
                pathname: NameRoutes.DetailsCompanyScreen.replace(
                  ':company_username',
                  event?.company?.company_username,
                ),
              });
            }}
          />



          {event?.views !== 0 && (
            <SeeCountEventText>
              {translate(TranslateConfig.PAGE_VIEWS)}: {formatNumber(event?.views)}
            </SeeCountEventText>
          )}

        </ContentPadder>
      </Content>



      <FooterAction
        buttonDisabled={!haveList()}
        buttonIcon={
          <IconList
            color={Colors.white}
          />
        }
        buttonLabel={haveList()
          ? translate(TranslateConfig.ACTION_JOIN_VIA_LIST)
          : translate(TranslateConfig.EMPTY_LIST_AVAILABLE)
        }
        buttonPress={() => {
          if (!availableUser) {
            openLoginScreen();
            return;
          }

          openListsScreen();
        }}

        actionDisabled={!haveTickets()}
        actionIcon={
          <IconDollar
            color={Colors.white}
          />
        }
        actionLabel={false
          ? paidEvent()
            ? translate(TranslateConfig.ACTION_SEE_AVAILABLES_TICKETS)
            : translate(TranslateConfig.ACTION_ENTRANCE_IN_EVENT)
          : translate(TranslateConfig.EMPTY_TICKET_AVAILABLE)
        }
        actionPress={() => {
          openTicketsScreen();
        }}
      />

    </Container>

  );
};



export default ActiveEventScreen;
