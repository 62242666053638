import React,
{
  useEffect,
  useState,
} from 'react';

import {
  useTranslation,
} from 'react-i18next';

import {
  useDispatch,
} from 'react-redux';

import {
  APP_MENU_SELECTED_ACTION,
} from '@noitada/redux-config/reducers/app.store';

import {
  Colors,
} from '@noitada/shared/constants';

import {
  EDrawerMenu,
} from '@noitada/shared/enums';

import {
  TranslateConfig,
} from '@noitada/shared/translations';

import {
  Title,
  Screen,
} from '../../../../components/Composh/web';

import {
  ButtonDrawer,
  ButtonMore,
} from '../../../../components/Controls';

import HeaderScreen from '../../../../components/Headers/HeaderScreen';

import TabBarStyled from '../../../../components/TabBar';

import TabReservesPast from './Tabs/TabPast';
import TabReservesUpcomming from './Tabs/TabUpcomming';



const UserReservesScreen: React.FC = () => {
  const dispatch = useDispatch();
  const { t: translate } = useTranslation();


  const screenTabs = [
    {
      name: 'ReservesUpcomming',
      title: translate(TranslateConfig.NEXT),
      component: <TabReservesUpcomming />,
    },
    {
      name: 'ReservesPast',
      title: translate(TranslateConfig.PAST),
      component: <TabReservesPast />,
    },
  ];


  const [stateIndex, setStateIndex] = useState(0);


  useEffect(() => {
    dispatch({ type: APP_MENU_SELECTED_ACTION, payload: EDrawerMenu.RESERVES });
  }, []);



  return (

    <Screen
      backgroundColor={Colors.appBackground}>

      <HeaderScreen
        backgroundColor={Colors.toolbarBackground}
        subBackgroundColor={Colors.toolbarBackground}
        leftIcon={
          <ButtonDrawer
            color={Colors.white}
          />
        }
        centerContent={
          <Title color={Colors.white}>
            {translate(TranslateConfig.RESERVES_MY)}
          </Title>
        }
        rightIcon={
          <ButtonMore
            color={Colors.white}
          />
        }
        noSubToolbarPadding>


        <TabBarStyled
          tabs={screenTabs}
          selected={stateIndex}
          onPress={(tabIndex: number) => {
            setStateIndex(tabIndex);
          }}
        />

      </HeaderScreen>



      {stateIndex === 0 && screenTabs[0].component}

      {stateIndex === 1 && screenTabs[1].component}

    </Screen>

  );
};



export default UserReservesScreen;
