import {
  EProTypes,
} from '../enums';



export function SetProInstallment(proReceived?: EProTypes) {
  const itemPro = proReceived;

  if (!itemPro) {
    return 0;
  }
  switch (itemPro) {
    case EProTypes.NO_PRO:
      return 0;

    case EProTypes.LIGHT:
      return 1;

    case EProTypes.MEDIUM:
      return 2;

    case EProTypes.SUPER:
      return 3;

    case EProTypes.PERSONALITY:
      return 4;

    default:
      return 0;
  }
};
