import React from 'react';

import {
  useTranslation,
} from 'react-i18next';

import {
  renderCardLogo,
} from '@noitada/shared/config/payments.config';

import {
  TranslateConfig,
} from '@noitada/shared/translations';

import {
  PaymentChooseContainer,
  PaymentInfoChooseView,
  PaymentChooseTitle,
  PaymentChooseCardNumber,
  PaymentChooseSubtitle,
  PaymentChooseImage,
} from './styled';



export interface IProps {
  onPress?: any;
  noChangeCard?: boolean;
  cardBrand?: string;
  last4?: string | null | undefined;
}



const PaymentChoose: React.FC<IProps> = (props: IProps) => {
  const { t: translate } = useTranslation();



  return (

    <PaymentChooseContainer
      onClick={props.onPress}>

      <PaymentInfoChooseView>

        <PaymentChooseTitle>
          {translate(TranslateConfig.PAYMENT_FORM)}
        </PaymentChooseTitle>


        <PaymentChooseCardNumber>
          {props.last4
            ? `**** **** **** ${props.last4}`
            : translate(TranslateConfig.EMPTY_CARD_REGISTERED)
          }
        </PaymentChooseCardNumber>


        {!props.noChangeCard && (
          <PaymentChooseSubtitle>
            {props.last4
              ? translate(TranslateConfig.ACTION_CLICK_TO_EDIT)
              : translate(TranslateConfig.ACTION_CLICK_TO_ADD)
            }
          </PaymentChooseSubtitle>
        )}

      </PaymentInfoChooseView>



      {props.cardBrand && (
        <PaymentChooseImage
          alt={'Card Logo'}
          src={renderCardLogo(props.cardBrand || 'unknown')}
        />
      )}

    </PaymentChooseContainer>

  );
};



export default PaymentChoose;
