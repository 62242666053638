import React,
{
  useEffect,
  useState,
} from 'react';

import {
  Colors, Images,
} from '@noitada/shared/constants';

import Vars from '@noitada/shared/environments/variables';

import {
  Content,
} from '../../../../components/Composh/web';

import {
  IconMenu,
} from '../../../../components/Icons';

import {
  NavBarScreen,
  NavBarContainer,
  NavBarContent,
  NavBarBrandButton,
  TermsPoliciesHeaderLogo,
  NavBarText,
  NavBarPaddingTopContainer,
  NavBarItemNotMobile,
  NavBarButtonNotMobile,
  NavBarMenuItemsContainer,
  NavBarMenuNotMobile,
  NavBarCollapseNotMobile,
  NavBarItemMobile,
  NavBarItemsMobile,
  NavBarButtonMobile,
  NavBarMenuButtonMobile,
  NavBarMenuMobile,
  TermsPoliciesHeaderSeparator,
} from './styled';



export interface IProps {
  type?: 'FIXED' | 'SCROLL';
  show?: boolean;
  subtitle?: string;
  navItems?: any;
  transparent?: boolean;
  children?: any;
}



const NavbarLanding: React.FC<IProps> = (props: IProps) => {
  const scroolSoft = document.querySelectorAll('a[href^="#"]');

  const backgroundComponent = Colors.appBackground;
  const subtitleHeader = props.subtitle;

  const isTransparent = props.children && props.type === 'SCROLL' ? true : false;

  const [headerTransparent, setHeaderTransparent] = useState<boolean>(isTransparent);

  const [show, setShow] = useState(false);



  function smoothScrollTo(endX: number, endY: number, duration: number) {
    const startX = window.scrollX || window.pageXOffset;
    const startY = window.scrollY || window.pageYOffset;
    const distanceX = endX - startX;
    const distanceY = endY - startY + 8; // 8 is an offset
    const startTime = new Date().getTime();

    duration = typeof duration !== 'undefined' ? duration : 400;

    // Easing function
    const easeInOutQuart = (time: number, from: number, distance: number, duration: number) => {
      if ((time /= duration / 2) < 1) return distance / 2 * time * time * time * time + from;
      return -distance / 2 * ((time -= 2) * time * time * time - 2) + from;
    };

    const timer = setInterval(() => {
      const time = new Date().getTime() - startTime;
      const newX = easeInOutQuart(time, startX, distanceX, duration);
      const newY = easeInOutQuart(time, startY, distanceY, duration);
      if (time >= duration) {
        clearInterval(timer);
      }
      window.scroll(newX, newY);
    }, 1000 / 60); // 60 fps
  };


  function getDistanceFromTheTop(element: { getAttribute: (arg0: string) => any; }) {
    const id = element.getAttribute('href');
    const getOffset = document.querySelector(id)?.offsetTop || 0;
    console.log('Distância do topo:', getOffset);
    return getOffset;
  };


  function scrollToSection(event: any) {
    event.preventDefault();
    const distanceFromTheTop = getDistanceFromTheTop(event.target) - 80;
    smoothScrollTo(0, distanceFromTheTop, 400);
  }


  const toggleMenu = () => {
    setShow(!show);
  };



  scroolSoft.forEach((link) => {
    link.addEventListener('click', scrollToSection);
  });


  useEffect(() => {
    if (props.children && props.type === 'SCROLL') {
      const navbar = document.querySelector<any>('#navbar');
      const pageTop = document.querySelector<any>('#page_top');

      let height = 50;

      window.addEventListener('scroll', () => {
        if (navbar && pageTop) {
          height = pageTop.offsetHeight - navbar.offsetHeight;
        }

        if (window.scrollY > height) {
          setHeaderTransparent(false);
        }
        else {
          setHeaderTransparent(true);
        }
      });
    }
  }, []);



  function renderNavBar() {
    return (

      <NavBarContainer
        transparent={headerTransparent}
        id='navbar'>

        <NavBarContent>

          <NavBarBrandButton
            id={'navbar-logo'}
            href={Vars().noitadaAppLink}>

            <TermsPoliciesHeaderLogo
              src={Images.logoAppNameWhite}
            />

            {subtitleHeader && (
              <TermsPoliciesHeaderSeparator />
            )}

            {subtitleHeader && (
              <NavBarText>
                {subtitleHeader.toUpperCase()}
              </NavBarText>
            )}

          </NavBarBrandButton>



          <NavBarMenuItemsContainer>

            <NavBarMenuNotMobile>
              {props.navItems && (
                <NavBarCollapseNotMobile
                  id={'navbar-buttons'}>

                  {props.navItems.map((items: any) => (
                    <NavBarItemNotMobile
                      href={items.navHref}>

                      <NavBarButtonNotMobile>
                        {String(items.navTitle).toUpperCase()}
                      </NavBarButtonNotMobile>

                    </NavBarItemNotMobile>
                  ))}

                </NavBarCollapseNotMobile>
              )}
            </NavBarMenuNotMobile>



            <NavBarMenuMobile
              onClick={() => {
                toggleMenu();
              }}>

              <NavBarMenuButtonMobile>
                <IconMenu
                  color={Colors.white}
                  size={24}
                />
              </NavBarMenuButtonMobile>

            </NavBarMenuMobile>

          </NavBarMenuItemsContainer>



          <NavBarItemsMobile
            show={show}
            onClick={() => {
              toggleMenu();
            }}>

            {props.navItems.map((items: any) => (
              <NavBarItemMobile
                href={items.navHref}>

                <NavBarButtonMobile>
                  {String(items.navTitle).toUpperCase()}
                </NavBarButtonMobile>

              </NavBarItemMobile>
            ))}

          </NavBarItemsMobile>

        </NavBarContent>
      </NavBarContainer>

    );
  }



  if (props.children && props.type === 'SCROLL') {
    return (

      <NavBarScreen
        backgroundColor={backgroundComponent}>

        {renderNavBar()}


        <Content
          id={'scroll-demo'}
          backgroundColor={backgroundComponent}>
          {props.children}
        </Content>

      </NavBarScreen>

    );
  }
  else if (props.children && props.type !== 'SCROLL') {
    return (

      <Content
        backgroundColor={backgroundComponent}>

        {renderNavBar()}

        <NavBarPaddingTopContainer>
          {props.children}
        </NavBarPaddingTopContainer>

      </Content>

    );
  }
  else {
    return renderNavBar();
  }
};



NavbarLanding.defaultProps = {
  type: 'SCROLL',
};



export default NavbarLanding;
