import styled,
{
  css,
} from 'styled-components';

import {
  Colors,
} from '@noitada/shared/constants';



export interface IProps {
  height?: number;
  width?: number;
  backgroundColor?: string;
  noShadow?: boolean;
}



export const ButtonShadow = css`
  elevation: 4;

  shadow-offset: 0 4px;
  shadow-color: #808080;
  shadow-radius: 10px;
  shadow-opacity: 0.8;
`;

export const ActionViewButton = styled.a`
  display: flex;
  flex-direction: column;
  align-self: center;
  align-items: center;
  justify-content: center;
  width: ${(props: IProps) => props.width ? `${props.width}px` : 'auto'};
  height: ${(props: IProps) => props.height ? `${props.height}px` : 'auto'};
  padding-top: 8px;
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 8px;
  background-color: ${(props: IProps) => props.backgroundColor || Colors.primary};
  border-top-left-radius: 15px;
  border-bottom-right-radius: 15px;

  ${(props: IProps) => props.noShadow && ButtonShadow};
`;

export const ActionViewText = styled.p`
  color: white;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
`;
