import React,
{
  useEffect,
  useRef,
  useState,
} from 'react';

import {
  useTranslation,
} from 'react-i18next';

import {
  useDispatch,
} from 'react-redux';

import {
  Formik,
  FormikProps,
} from 'formik';

import {
  AddPersonReserve,
  FindUser,
} from '@noitada/axios-config/endpoints/conta-global.endpoints';

import AddPersonModel from '@noitada/axios-config/models/User/add-person.model';

import {
  APP_MODAL_LOADING_ACTION,
  APP_SNACKBAR_ACTION,
} from '@noitada/redux-config/reducers/app.store';

import {
  ArrayColors,
} from '@noitada/shared/arrays';

import {
  Colors,
  Sizes,
  Values,
} from '@noitada/shared/constants';

import {
  EUserStatus,
} from '@noitada/shared/enums';

import {
  TranslateConfig,
} from '@noitada/shared/translations';

import AddPesonValidationSchema from '@noitada/shared/validations/User/add-person.validations';

import {
  Mask,
} from '../../components/Composh/plugins';

import {
  CheckBox,
  Container,
  Overlay,
} from '../../components/Composh/web';

import FooterChoose from '../../components/Footers/FooterChoose';
import HeaderModal from '../../components/Headers/HeaderModal';
import InputText from '../../components/Inputs/InputText';
import LoadingScreen from '../../components/Loadings/LoadingScreen';

import {
  ButtonText,
  FooterDescription,
  RequiredText,
} from '../../styles/styled.layout';

import {
  AddPersonContainer,
  AddPersonContent,
  AddFooterDescription,
} from './styled';

const width = 600;
const height = 600;



export interface IProps {
  visible?: boolean;
  canAddGhost?: boolean;
  onCancelPress?: any;
  onPress?: any;
  verification?: Function;
}



const AddNewPersonModal: React.FC<IProps> = (props: IProps) => {
  const visibleModal = props.visible;
  if (!visibleModal) {
    return null;
  }

  const dispatch = useDispatch();
  const { t: translate } = useTranslation();


  const widthLine = width - 240;
  const heightLine = height - 87;

  const formik = useRef<FormikProps<typeof AddPersonModel>>(null);

  const [isCpf, setIsCpf] = useState('TEXT');
  const [emailInput, setEmailInput] = useState(false);

  const [notInformation, setNotInformation] = useState(false);

  const [loading, setLoading] = useState<boolean>(false);
  const [mounted, setMounted] = useState<boolean>(false);



  function showSnackBarProps(snackColor: string, snackText: string) {
    dispatch({
      type: APP_SNACKBAR_ACTION,
      payload: {
        visible: true,
        color: snackColor,
        text: snackText,
      },
    });
  }


  function updateForm() {
    setTimeout(() => {
      setMounted(true);
    }, Values.mountTime);
  }


  function onCancel() {
    if (props.onCancelPress) {
      props.onCancelPress();
    }
  }


  async function savePersonReserve(values: any) {
    if (notInformation) {
      props.onPress({
        id: '',
        status: EUserStatus.GHOST,
        level: EUserStatus.GHOST,
      });
      onCancel();
    }
    else {
      const payload = {
        user_cpf: values.usernameCpf,
      };

      try {
        setLoading(true);
        dispatch({ type: APP_MODAL_LOADING_ACTION, payload: true });

        let response;

        if (props.canAddGhost) {
          response = await AddPersonReserve(payload);
        }
        else {
          response = await FindUser(payload.user_cpf);
          if (!response) {
            showSnackBarProps(Colors.danger, translate(TranslateConfig.ERROR_USER_DOESNOT_EXIST));
            onCancel();
            return;
          }
        }

        if (props.verification && await props.verification(response?.id)) {
          showSnackBarProps(Colors.danger, translate(TranslateConfig.ERROR_USER_IN_LIST));
          onCancel();
          return;
        }

        if (response) {
          showSnackBarProps(Colors.accept, translate(TranslateConfig.SUCCESS_USER_ADDED));
          props.onPress(response);
          onCancel();
        }
        else {
          console.error('Error', response);
          showSnackBarProps(Colors.danger, response.message || translate(TranslateConfig.ERROR_MAKE_REGISTER));
        }
      }
      catch (error: any) {
        console.error(error);

        const message = error?.hasError && error?.message && typeof error?.message === 'string'
          ? error?.message
          : translate(TranslateConfig.ERROR_HAD);
        showSnackBarProps(Colors.danger, message);
      }
      finally {
        setLoading(false);
        dispatch({ type: APP_MODAL_LOADING_ACTION, payload: false });
      }
    }
  }


  function isCpfOrUsername(value: string) {
    if (/^[0-9]/.test(value) && props.canAddGhost) {
      // se começa com número, considera como CPF
      return 'CPF';
    }
    else {
      // senão, usa a validação de nome de usuário
      return 'TEXT';
    }
  }


  useEffect(() => {
    updateForm();
  }, [formik]);



  return (

    <Overlay
      visible={props.visible}
      showBackground={true}
      overlayColor={Colors.menuOverlay}
      onOverlayPress={props.onCancelPress}>

      <Formik
        enableReinitialize
        validateOnMount={false}
        innerRef={formik}
        initialValues={AddPersonModel}
        validationSchema={AddPesonValidationSchema}
        onSubmit={(values) => {
          // KeyboardModal.dismiss();
          savePersonReserve(values);
        }}>
        {({
          dirty,
          isValid,
          handleSubmit,
          handleChange,
          handleBlur,
          setFieldValue,
          values,
          errors,
        }) => (

          <AddPersonContainer
            width={widthLine}
            height={heightLine}>

            <HeaderModal
              title={translate(TranslateConfig.ACTION_ADD_PERSON)}
              onPress={() => {
                onCancel();
              }}
            />



            {!mounted && (
              <LoadingScreen
                type={'SCREEN'}
                color={Colors.primary}
              />
            )}



            {mounted && (
              <Container style={{ flex: 1 }}>
                <AddPersonContent>

                  <RequiredText>
                    * {translate(TranslateConfig.HELP_INPUT_REQUIRED)}
                  </RequiredText>


                  <InputText
                    disabled={loading || notInformation}
                    autoCorrect
                    autoCapitalize={'words'}
                    type={isCpf}
                    labelText={`${translate(TranslateConfig.USERNAME)} ${props.canAddGhost ? `${translate(TranslateConfig.OR)} ${translate(TranslateConfig.CPF)}` : ''} *`}
                    placeholderText={translate(TranslateConfig.HELP_INSERT_USER)}
                    helpText={translate(props.canAddGhost ? errors.usernameCpf || '' : '')}
                    countLimit={Values.usernameMaxCount}
                    value={values.usernameCpf}
                    onChange={(value: string) => {
                      const isCpf = isCpfOrUsername(value);

                      setFieldValue('usernameCpf', value);
                      setIsCpf(isCpf);
                      setEmailInput(isCpf === 'CPF' && Mask.isValid('cpf', value));
                    }}
                    onBlur={handleBlur('usernameCpf')}
                  />


                  {emailInput && (
                    <InputText
                      disabled={loading || notInformation}
                      autoCorrect
                      autoCapitalize={'words'}
                      type={'TEXT'}
                      labelText={translate(TranslateConfig.EMAIL)}
                      placeholderText={translate(TranslateConfig.EMAIL)}
                      helpText={translate(errors.email || '')}
                      countLimit={Values.emailMaxCount}
                      value={values.email}
                      onChange={handleChange('email')}
                      onBlur={handleBlur('email')}
                    />
                  )}


                  {props.canAddGhost && (
                    <CheckBox
                      // containerStyle={customCheckbox.checkboxContainer}
                      activeOpacity={Sizes.ButtonActiveOpacity}
                      checked={notInformation}
                      title={translate(TranslateConfig.HELP_USER_HAVENT_DATA)}
                      titleStyle={{
                        color: Colors.white,
                      }}
                      // checkedIcon="dot-circle-o"
                      // uncheckedIcon="circle-o"
                      checkedColor={Colors.primary}
                      uncheckedColor={Colors.buttonDisabled}
                      onPress={() => {
                        setFieldValue('emptyPerson', !notInformation);
                        setNotInformation(!notInformation);

                        setFieldValue('usernameCpf', null);
                        setFieldValue('email', null);
                      }}
                    />
                  )}



                  <AddFooterDescription>
                    <FooterDescription>
                      {translate(TranslateConfig.HELP_COMPANY_QUANTITY_ACCEPT)}
                    </FooterDescription>
                  </AddFooterDescription>

                </AddPersonContent>



                <FooterChoose
                  cancelDisabled={loading}
                  cancelColor={loading
                    ? ArrayColors.arrayDisabled
                    : ArrayColors.arrayCancel
                  }
                  cancelLabel={
                    <ButtonText
                      color={Colors.white}>
                      {translate(TranslateConfig.ACTION_CANCEL)}
                    </ButtonText>
                  }
                  cancelPress={() => {
                    onCancel();
                  }}

                  okDisabled={!dirty || !isValid || loading}
                  okColor={!dirty || !isValid || loading
                    ? ArrayColors.arrayDisabled
                    : ArrayColors.arrayOk
                  }
                  okLabel={
                    <ButtonText
                      color={Colors.white}>
                      {translate(TranslateConfig.ACTION_ADD_PERSON)}
                    </ButtonText>
                  }
                  okPress={() => {
                    handleSubmit();
                  }}
                />

              </Container>
            )}

          </AddPersonContainer>

        )}
      </Formik>
    </Overlay>

  );
};



export default AddNewPersonModal;
