import React,
{
  useEffect,
  useState,
} from 'react';

import {
  useTranslation,
} from 'react-i18next';

import {
  useDispatch,
  useSelector,
} from 'react-redux';

import {
  useHistory,
} from 'react-router-dom';

import {
  GetFutureEvents,
} from '@noitada/axios-config/endpoints/events.endpoints';

import {
  RootState,
} from '@noitada/redux-config';

import {
  APP_SNACKBAR_ACTION,
} from '@noitada/redux-config/reducers/app.store';

import {
  USER_SET_CURRENT_COMPANY,
} from '@noitada/redux-config/reducers/company.store';

import {
  USER_SET_CURRENT_EVENT,
} from '@noitada/redux-config/reducers/event.store';

import {
  Colors,
  Images,
} from '@noitada/shared/constants';

import {
  TranslateConfig,
} from '@noitada/shared/translations';

import {
  formatDateWithPattern,
  formatMomentWithPattern,
  FormatIsoDateWithPattern,
} from '@noitada/shared/utils/date.utils';

import {
  stringCapitalize,
} from '@noitada/shared/utils/string.utils';

import ButtonCityUser from '../../../components/Buttons/ButtonCitiesApp/CityUser';
import CardEventsHome from '../../../components/Cards/CardEvents/CardEventsHome';

import {
  Container,
  ToolbarGroup,
} from '../../../components/Composh/web';

import CalendarHorizontal from '../../../components/Composh/web/CalendarHorizontal';

import {
  ButtonDrawer,
  ButtonMore,
  ButtonNotifications,
  ButtonSearch,
} from '../../../components/Controls';

import EmptyContent from '../../../components/Empty';
import HeaderScreen from '../../../components/Headers/HeaderScreen';
import LoadingScreen from '../../../components/Loadings/LoadingScreen';
import HomeTitle from '../../../components/Titles/HomeTitle';

import NameRoutes from '../../../navigation/names';

import {
  EventScrollFlatList,
  TitleEventView,
} from './styled';



const TabEventsScreen: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t: translate } = useTranslation();


  const localities = useSelector((state: RootState) => state.localities.selected);


  const [events, setEvents] = useState<Array<any>>([]);

  const [selectedDate, setSelectedDate] = useState(new Date());
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const [loading, setLoading] = useState(false);

  const dateStringText = `${stringCapitalize(`${formatMomentWithPattern(selectedDate, 'dddd')} - ${formatDateWithPattern(selectedDate, 'dd/MM/yyyy')}`)}`;



  function showSnackBarProps(snackColor: string, snackText: string) {
    dispatch({
      type: APP_SNACKBAR_ACTION, payload: {
        visible: true,
        color: snackColor,
        text: snackText,
      },
    });
  }


  async function getEvents(timestamp?: Date) {
    const date = timestamp ? new Date(timestamp) : new Date();
    date.setHours(0);
    date.setMinutes(0);
    const from = date.toISOString();

    try {
      setLoading(true);

      const response = await GetFutureEvents(from, localities);

      if (response) {
        setEvents(response);
      }
      else {
        setEvents([]);
      }
    }
    catch (error: any) {
      console.error(error);
      setEvents([]);

      const message = error?.hasError && error?.message && typeof error?.message === 'string'
        ? error?.message
        : translate(TranslateConfig.ERROR_HAD);
      showSnackBarProps(Colors.danger, message);
    }
    finally {
      setLoading(false);
    }
  }


  function renderItem(item: any, index: any) {
    return (

      <CardEventsHome
        key={index}
        companyName={item?.company?.name}
        companyImage={item?.company?.picture}
        eventName={item?.name}
        eventImage={item?.picture}
        startDate={FormatIsoDateWithPattern(item?.startAt, 'dd/MM/yyyy - HH:mm')}
        endDate={FormatIsoDateWithPattern(item?.endAt, 'dd/MM/yyyy - HH:mm')}
        place={item?.address?.district}
        availables={item?.availables}
        onPicturePress={() => {
          dispatch({ type: USER_SET_CURRENT_COMPANY, payload: item?.company });
          history.push({
            pathname: NameRoutes.DetailsCompanyScreen.replace(
              ':company_username',
              item?.company?.company_username,
            ),
          });
        }}
        onPress={() => {
          dispatch({ type: USER_SET_CURRENT_EVENT, payload: item });
          history.push({
            pathname: NameRoutes.DetailsEventScreen.replace(
              ':event_id',
              item?.idShort,
            ),
          });
        }}
      />

    );
  }


  function listHeaderComponent() {
    return (

      <TitleEventView>
        <HomeTitle
          title={dateStringText}
          size={22}
        />
      </TitleEventView>

    );
  }


  function renderLoading() {
    return (

      <LoadingScreen
        type={'SCREEN'}
        color={Colors.primary}
      />

    );
  }


  function listEmptyComponent() {
    return (

      <EmptyContent
        image={Images.noParty}
        title={translate(TranslateConfig.EMPTY_HIGHLIGHTS_EVENTS)}
      />

    );
  }


  function returnStartDate(): void {
    const startDate = new Date();
    setStartDate(startDate);

    const endDate = new Date();
    endDate.setDate(new Date().getDate() + 6);
    setEndDate(endDate);
  }



  useEffect(() => {
    returnStartDate();
  }, []);


  useEffect(() => {
    getEvents();
  }, [localities]);



  return (

    <Container>

      <HeaderScreen
        backgroundColor={Colors.toolbarBackground}
        subBackgroundColor={Colors.toolbarBackground}
        leftIcon={
          <ButtonDrawer
            color={Colors.white}
          />
        }
        centerContent={
          <ButtonCityUser />
        }
        rightIcon={
          <ToolbarGroup>

            <ButtonSearch
              color={Colors.white}
            />

            <ButtonNotifications
              color={Colors.white}
            />

            <ButtonMore
              color={Colors.white}
            />

          </ToolbarGroup>
        }
        noSubToolbarPadding>

        <CalendarHorizontal
          hideArrows
          color={Colors.accent}
          textColor={Colors.white}
          backgroundColor={[
            Colors.transparent,
            Colors.calendarColor,
          ]}
          selectedDate={selectedDate}
          startDate={startDate}
          endDate={endDate}
          onSelectDate={(dateFromCalendar: Date) => {
            setSelectedDate(dateFromCalendar);
            getEvents(dateFromCalendar);
          }}
        />

      </HeaderScreen>



      <EventScrollFlatList
        loading={loading}
        data={events}
        renderItem={renderItem}
        listHeaderComponent={listHeaderComponent()}
        listLoadingComponent={renderLoading()}
        listEmptyComponent={listEmptyComponent()}
      />

    </Container>

  );
};



export default TabEventsScreen;
