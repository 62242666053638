export enum EProTypes {
  NO_PRO = 'NO_PRO',
  LIGHT = 'LIGHT',
  MEDIUM = 'MEDIUM',
  SUPER = 'SUPER',
  SUPER_FREEMIUM = 'SUPER_FREEMIUM',
  PERSONALITY = 'PERSONALITY',
}



export default EProTypes;
