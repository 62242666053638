import React,
{
  useEffect,
  useState,
} from 'react';

import {
  useTranslation,
} from 'react-i18next';

import {
  useDispatch,
  useSelector,
} from 'react-redux';

import {
  useHistory,
} from 'react-router-dom';

import {
  GetUserCreditCard,
} from '@noitada/axios-config/endpoints/conta-global.endpoints';

import {
  GetEnabledReserves,
  UserMakeReservation,
} from '@noitada/axios-config/endpoints/reserves.endpoints';

import {
  RootState,
} from '@noitada/redux-config';

import {
  APP_MODAL_LOADING_ACTION,
  APP_SNACKBAR_ACTION,
} from '@noitada/redux-config/reducers/app.store';

import {
  ArrayColors,
} from '@noitada/shared/arrays';

import {
  Colors,
  Images,
  Values,
} from '@noitada/shared/constants';

import {
  EUserStatus,
} from '@noitada/shared/enums';

import {
  TranslateConfig,
} from '@noitada/shared/translations';

import {
  FormatIsoDateWithPattern,
} from '@noitada/shared/utils/date.utils';

import {
  validateString,
} from '@noitada/shared/utils/string.utils';

import ButtonSeeMore from '../../../components/Buttons/ButtonSeeMore';
import DateViewCalendar from '../../../components/Cards/CardDate';
import CardReserveInfoPerson from '../../../components/Cards/CardReserves/CardReserveInfoPerson';

import {
  Mask,
} from '../../../components/Composh/plugins';

import {
  Body,
  Container,
  Content,
  Epigraph,
  Screen,
  SubTitle,
  Title,
} from '../../../components/Composh/web';

import {
  ButtonBack,
  ButtonDelete,
} from '../../../components/Controls';

import DetailsHeaderBlur from '../../../components/Details/DetailsHeaderBlur';
import EmptyContent from '../../../components/Empty';
import FooterChoose from '../../../components/Footers/FooterChoose';
import HeaderScreen from '../../../components/Headers/HeaderScreen';

import {
  IconPerson,
} from '../../../components/Icons';

import InputText from '../../../components/Inputs/InputText';
import LoadingScreen from '../../../components/Loadings/LoadingScreen';
import PaymentChoose from '../../../components/Payments/PaymentChoose';

import {
  FormatMoney,
} from '../../../config/mask.config';

import AddNewPersonModal from '../../../modals/AddNewPersonModal';
import PaymentsModal from '../../../modals/Payments';

import NameRoutes from '../../../navigation/names';

import {
  ButtonText,
  ContentPadderHorizontal,
  FooterDescription,
} from '../../../styles/styled.layout';

import RevisionReserveModal from './RevisionModal';
import SeeDatesModal from './SeeDatesModal';

import {
  ReservePersonContainer,
  ReservePersonView,
  ReservePersonContent,
  ReserveLabelPerson,
  ReserveWarningPerson,
  ReserveCountView,
  ReserveCountTitle,
  ReservePersonAlertText,
  ReservePersonsView,
  ReserveMePersonText,
  ReserveDatesContainer,
  ReservesToggleDateView,
  ReservesDateToggle,
  ReserveDateSelectText,
  ReserveOptionsContainer,
  ReservesToggleOptionsView,
  ReservesHourToggle,
  ReserveTablesText,
  ReservePriceText,
  ReserveOthersContainer,
  ReservePayContainer,
  CouponChooseStyled,
} from './styled';



const CompanyReservesScreen: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t: translate } = useTranslation();


  const companySelected = useSelector((state: RootState) => state.company.selected);
  const user = useSelector((state: RootState) => state.user.actualUser);
  const availableUser = user?.id;


  const myUserObject = availableUser ? [user] : [];
  const [people, setPeople] = useState<Array<any>>(myUserObject);

  const [reserveData, setReserveData] = useState<Array<any>>([]);

  const [selectedReservationIdx, setSelectedReservationIdx] = useState<number>(0);
  const [selectedReservation, setSelectedReservation] = useState<any | null>(null);
  const [selectedPlaceIdx, setSelectedPlaceIdx] = useState<number>(0);

  const [availableHours, setAvailableHours] = useState<Array<string>>([]);
  const [selectedHour, setSelectedHour] = useState<string | null>(null);

  const [dates, setDates] = useState<Array<string>>([]);
  const [places, setPlaces] = useState<Array<string>>([]);

  const [observations, setObservations] = useState('');

  const [showSeeDatesModal, setShowSeeDatesModal] = useState<boolean>(false);
  const [showAddPersonModal, setShowAddPersonModal] = useState<boolean>(false);
  const [showConfirmation, setShowConfirmation] = useState<boolean>(false);

  const [userReserveData, setUserReserveData] = useState<any | null>(null);

  const [paymentModal, setPaymentModal] = useState(false);
  const [mainCreditCard, setMainCreditCard] = useState<any>(null);
  const [cardItem, setCardItem] = useState<any>(null);

  const [loading, setLoading] = useState(false);



  function showSnackBarProps(snackColor: string, snackText: string) {
    dispatch({
      type: APP_SNACKBAR_ACTION, payload: {
        visible: true,
        color: snackColor,
        text: snackText,
      },
    });
  }


  async function getPaymentsType() {
    try {
      const data = await GetUserCreditCard();

      if (!data || data?.length === 0) {
        // FIXME: Transformar em modal
        alert(translate(TranslateConfig.EMPTY_CARD_NO_ADDED));
        // history.push(NameRoutes);
        return;
      }

      setCardItem(data);

      const mainCard = data?.filter((e: any) => e?.main === true)[0];
      setMainCreditCard(mainCard);
    }
    catch (error: any) {
      console.error(error);

      const message = error?.hasError && error?.message && typeof error?.message === 'string'
        ? error?.message
        : translate(TranslateConfig.ERROR_HAD);
      showSnackBarProps(Colors.danger, message);
    }
  }


  function treatGetReserveReturn(response: any) {
    const reservationsResponseData = response;

    const viewModel: Array<any> = [];

    // Checks the tables available for each location
    for (let index = 0; index < reservationsResponseData.length; index++) {
      const reserveData = reservationsResponseData[index];

      const tables = reserveData?.tableQuantity - reserveData?.reservations.length;

      // Modified API list for the front
      if (tables > 0) {
        viewModel.push({
          forDate: reserveData?.forDate,
          places: [{
            id: reserveData?.id,
            place: reserveData?.environment,
            price: reserveData?.tablePrice,
            limit: reserveData?.tableLimit,
            tables,
            after: reserveData?.afterHour,
            before: reserveData?.beforeHour,
          }],
        });
      }
    }

    // Put the places by date
    for (let i = 0; i < viewModel.length - 1; i++) {
      const curr = viewModel[i];
      const next = viewModel[i + 1];

      if (curr.forDate === next.forDate) {
        curr.places.push(next.places[0]);
        viewModel.splice(i + 1, 1);
      }
    }

    return viewModel;
  }


  function resetScreen() {
    setReserveData([]);

    setSelectedReservationIdx(0);
    setSelectedReservation(null);
    setSelectedPlaceIdx(0);

    setAvailableHours([]);
    setSelectedHour(null);

    setDates([]);
    setPlaces([]);

    setObservations('');

    setShowSeeDatesModal(false);
    setShowAddPersonModal(false);
    setShowConfirmation(false);

    setUserReserveData(null);
  }


  async function getReservations() {
    setLoading(true);

    if (availableUser) {
      getPaymentsType();
    }

    try {
      const response = await GetEnabledReserves(companySelected?.idShort);

      if (response?.length === 0) {
        resetScreen();
        return;
      }

      if (response) {
        const treatedResponse = treatGetReserveReturn(response);
        setReserveData(treatedResponse);
      }
      else {
        showSnackBarProps(Colors.danger, translate(TranslateConfig.ERROR_NO_DATA_FOUND));
      }
    }
    catch (error: any) {
      console.error(error);

      const message = error?.hasError && error?.message && typeof error?.message === 'string'
        ? error?.message
        : translate(TranslateConfig.ERROR_HAD);
      showSnackBarProps(Colors.danger, message);
    }
    finally {
      setLoading(false);
    }
  }


  function cancelButtonPress() {
    history.goBack();
  }


  function confirmRevision() {
    if (!availableUser) {
      history.push(NameRoutes.AccessScreen);
      return;
    }

    if (selectedPlaceIdx !== null && selectedReservation && selectedHour !== null) {
      setUserReserveData({
        company: companySelected,
        reservation: selectedReservation?.places[selectedPlaceIdx],
        forDate: selectedReservation?.forDate,
        selected: {
          people,
          time: selectedHour,
        },
      });

      setShowConfirmation(true);
    }
    else {
      showSnackBarProps(Colors.danger, translate(TranslateConfig.VALIDATE_ALL_SELECTED));
    }
  }


  function completarZerosEsquerda(str: any, length: any) {
    const resto: any = length - String(str).length;
    const result: any = '0'.repeat(resto > 0 ? resto : '0') + str;
    return result;
  }

  async function createReservation() {
    if (!availableUser) {
      history.push(NameRoutes.AccessScreen);
      return;
    }

    setShowConfirmation(false);
    const dateChoosed = userReserveData?.forDate?.slice(0, -14).split('-');

    const data_split = userReserveData?.selected?.time?.split(':')?.map((e) => completarZerosEsquerda(e, 2));
    const dateForReserve = `${dateChoosed[0]}-${dateChoosed[1]}-${dateChoosed[2]}T${data_split[0]}:${data_split[1]}:00.${'000Z'}`;
    // const dateForReserve = new Date().toISOString();

    const usersInReserve: Array<any> = [];

    people.forEach((item) => {
      usersInReserve.push({ id: item.id });
    });

    const apiRequestData = {
      card: mainCreditCard?.id,
      reservationId: userReserveData?.reservation?.id,
      peopleNumber: userReserveData?.selected?.people?.length,
      reservationAt: dateForReserve,
      users: usersInReserve,
      observations,
    };


    try {
      dispatch({ type: APP_MODAL_LOADING_ACTION, payload: true });

      const response = await UserMakeReservation(apiRequestData);
      if (response?.status !== 200 && response?.data?.error) {
        showSnackBarProps(Colors.danger, response?.data?.message || translate(TranslateConfig.ERROR_HAD));
      }

      if (response?.status === 200) {
        history.replace(NameRoutes.ReserveConfirmedScreen);
      }
    }
    catch (error: any) {
      console.error(error);

      const message = error?.hasError && error?.message && typeof error?.message === 'string'
        ? error?.message
        : translate(TranslateConfig.ERROR_HAD);
      showSnackBarProps(Colors.danger, message);
    }
    finally {
      dispatch({ type: APP_MODAL_LOADING_ACTION, payload: false });
    }
  };


  function listEmptyComponent() {
    return (

      <EmptyContent
        image={Images.noBar}
        title={translate(TranslateConfig.EMPTY_RESERVE_REGISTERED)}
      />

    );
  }


  function selectDate(index: number) {
    setSelectedReservation(reserveData[index]);
    setSelectedReservationIdx(index);

    setSelectedPlaceIdx(0);
    setPeople(myUserObject);
    setSelectedHour(null);
  }


  const handleRemoveItem = (index: number) => {
    const newItems = [...people];
    newItems.splice(index, 1);
    setPeople(newItems);
  };



  useEffect(() => {
    getReservations();
  }, []);


  useEffect(() => {
    if (reserveData) {
      setSelectedReservation(reserveData[0]);
      setSelectedPlaceIdx(0);
    }
  }, [reserveData]);


  // Updates the hours available according to the date + company selection
  useEffect(() => {
    // Hours for this company
    if (selectedReservation) {
      // Available dates
      const dates: Array<string> = [];
      for (let index = 0; index < reserveData?.length; index++) {
        const reserve = reserveData[index];
        dates.push(reserve?.forDate);
      }
      setDates(dates);

      // Available places
      const places: Array<string> = [];
      for (let index = 0; index < selectedReservation?.places.length; index++) {
        places.push(selectedReservation?.places[index].place);
      }
      setPlaces(places);

      // Available hours
      const newAvailableHours: Array<string> = [];
      for (let index = selectedReservation?.places[selectedPlaceIdx].after; index <= selectedReservation?.places[selectedPlaceIdx].before; index++) {
        newAvailableHours.push(index + ':00');
      }
      setAvailableHours(newAvailableHours);
    }
  }, [selectedReservation]);



  return (

    <Screen
      backgroundColor={Colors.appBackground}>

      <Container>

        <HeaderScreen
          backgroundColor={Colors.toolbarBackground}
          subBackgroundColor={Colors.toolbarBackground}
          leftIcon={
            <ButtonBack
              close
              color={Colors.white}
            />
          }
          centerContent={
            <Body>
              <Title
                color={Colors.white}>
                {translate(TranslateConfig.RESERVES)}
              </Title>

              <SubTitle>
                {companySelected?.name}
              </SubTitle>
            </Body>
          }
        />



        {loading && (
          <LoadingScreen
            type={'SCREEN'}
            color={Colors.primary}
          />
        )}



        {!loading && reserveData?.length <= 0 && (
          listEmptyComponent()
        )}



        {!loading && reserveData?.length > 0 && (
          <Content>

            <DetailsHeaderBlur
              image={companySelected?.picture}
              banner={companySelected?.picture}
              name={companySelected?.name}
              username={companySelected?.company_username}
            />



            <ReserveDatesContainer>

              <ContentPadderHorizontal>

                <Epigraph.Section
                  text={translate(TranslateConfig.RESERVE_DATE)}
                  textColor={Colors.primary}
                  borderBottomColor={Colors.primary}
                />


                <ReservesToggleDateView>
                  {dates.map((item: any, index: number) => {
                    if (index < 6) {
                      const dateToShow: Date = new Date(item);

                      return (

                        <ReservesDateToggle
                          key={index}
                          noToggle
                          flexToggle={1}
                          selected={selectedReservationIdx === index}
                          backgroundColor={Colors.cardBackground}
                          backgroundTintColor={Colors.primary}
                          textColor={Colors.white}
                          borderRadius={5}
                          onPress={() => {
                            selectDate(index);
                          }}>

                          <DateViewCalendar
                            textColor={Colors.white}
                            date={dateToShow}
                            size={13}
                          />

                        </ReservesDateToggle>

                      );
                    }
                  })}
                </ReservesToggleDateView>

              </ContentPadderHorizontal>



              <ReserveDateSelectText>
                {`${translate(TranslateConfig.DATE_SELECTED)}: ${FormatIsoDateWithPattern(reserveData[selectedReservationIdx]?.forDate, 'dd/MM/yyyy') || '-'}`}
              </ReserveDateSelectText>



              <ButtonSeeMore
                title={translate(TranslateConfig.ACTION_SEE_DATES)}
                onPress={() => {
                  setShowSeeDatesModal(true);
                }}
              />

            </ReserveDatesContainer>



            <ReserveOptionsContainer>

              <Epigraph.Section
                text={translate(TranslateConfig.ENVIRONMENT)}
                textColor={Colors.primary}
                borderBottomColor={Colors.primary}
              />


              <ReservesToggleOptionsView>
                {places.map((item: any, index: number) => {
                  return (

                    <ReservesHourToggle
                      key={index}
                      noToggle
                      selected={selectedPlaceIdx === index}
                      displayValue={item}
                      backgroundColor={Colors.cardBackground}
                      backgroundTintColor={Colors.primary}
                      borderRadius={5}
                      textColor={Colors.white}
                      textTintColor={Colors.white}
                      onPress={() => {
                        setSelectedPlaceIdx(index);
                        setPeople(myUserObject);
                        setSelectedHour(null);
                      }}
                    />

                  );
                })}
              </ReservesToggleOptionsView>

            </ReserveOptionsContainer>



            <ReserveOptionsContainer>

              <Epigraph.Section
                text={translate(TranslateConfig.RESERVE_HOUR)}
                textColor={Colors.primary}
                borderBottomColor={Colors.primary}
              />


              <ReservesToggleOptionsView>
                {availableHours.map((item: any, index: number) => {
                  return (

                    <ReservesHourToggle
                      key={index}
                      noToggle
                      selected={selectedHour === item}
                      displayValue={item}
                      backgroundColor={Colors.cardBackground}
                      backgroundTintColor={Colors.primary}
                      borderRadius={5}
                      textColor={Colors.white}
                      textTintColor={Colors.white}
                      onPress={() => {
                        setSelectedHour(availableHours[index]);
                      }}
                    />

                  );
                })}
              </ReservesToggleOptionsView>

            </ReserveOptionsContainer>



            <ReservePersonContainer>

              <ContentPadderHorizontal>

                <Epigraph.Section
                  text={translate(TranslateConfig.PEOPLE_NUMBER)}
                  textColor={Colors.primary}
                  borderBottomColor={Colors.primary}
                />


                <ReservePersonView>

                  <ReservePersonContent>
                    <ReserveLabelPerson>
                      {translate(TranslateConfig.PEOPLE_NUMBER)}
                    </ReserveLabelPerson>

                    <ReserveWarningPerson>
                      {translate(TranslateConfig.HELP_MINIMAL_AGE)}
                    </ReserveWarningPerson>
                  </ReservePersonContent>



                  <ReserveCountView>
                    <IconPerson
                      color={Colors.white}
                      size={28}
                    />

                    <ReserveCountTitle>
                      {people.length}
                    </ReserveCountTitle>
                  </ReserveCountView>

                </ReservePersonView>


                <ReservePersonAlertText>
                  {translate(TranslateConfig.RESERVE_PEOPLE_LIMIT).replace(
                    '{limitReserve}',
                    selectedReservation?.places[selectedPlaceIdx].limit,
                  )}
                </ReservePersonAlertText>



                <ReservePersonsView>
                  <ReserveMePersonText>
                    {people.length > 1
                      ? translate(TranslateConfig.ME_AND_GUESTS).toUpperCase()
                      : translate(TranslateConfig.ONLY_FOR_ME).toUpperCase()
                    }
                  </ReserveMePersonText>


                  {people?.map((item: any, index: number) => {
                    if (index !== 0) {
                      return (

                        <CardReserveInfoPerson
                          key={index}
                          name={item?.status === EUserStatus.REGISTERED
                            ? `@${item?.publicName}`
                            : item?.status === EUserStatus.PRE_REGISTERED
                              ? `${translate(TranslateConfig.CPF)}: ${Mask.toMask('cpf', item?.cpf)}`
                              : translate(TranslateConfig.EMPTY_USER_DATA)
                          }
                          username={item?.status === EUserStatus.REGISTERED
                            ? `@${item?.username}`
                            : item?.status === EUserStatus.PRE_REGISTERED
                              ? translate(TranslateConfig.USER_PRE_REGISTER)
                              : translate(TranslateConfig.EMPTY_USER_NO_RECOG)
                          }
                          birthday={item?.birthday
                            ? false // FIXME
                            : false
                          }
                          verified={item?.status === EUserStatus.REGISTERED
                            ? item?.people?.verified
                            : false
                          }
                          image={item?.picture || ''}
                          level={item?.people?.level}
                          deleteIcon={
                            <ButtonDelete
                              color={Colors.danger}
                              onPress={() => {
                                handleRemoveItem(index);
                              }}
                            />
                          }
                        />

                      );
                    }
                  })}
                </ReservePersonsView>

              </ContentPadderHorizontal>



              <ButtonSeeMore
                disabled={!availableUser || people.length >= selectedReservation?.places[selectedPlaceIdx].limit}
                title={translate(TranslateConfig.ACTION_ADD_PEOPLE)}
                onPress={() => {
                  setShowAddPersonModal(true);
                }}
              />

            </ReservePersonContainer>



            <ReserveOthersContainer>

              <Epigraph.Section
                text={translate(TranslateConfig.MORE_INFORMATIONS)}
                textColor={Colors.primary}
                borderBottomColor={Colors.primary}
              />


              <InputText
                disabled={loading || !availableUser}
                type={'TEXT'}
                labelText={translate(TranslateConfig.OBSERVATIONS)}
                placeholderText={translate(TranslateConfig.OPTIONAL)}
                value={observations}
                countLimit={Values.aboutText}
                onChange={(rawText: string) => {
                  setObservations(rawText);
                }}
              />



              <FooterDescription>
                {translate(TranslateConfig.HELP_RESERVE_OBS_EXAMPLE)}
              </FooterDescription>

            </ReserveOthersContainer>



            <ReservePayContainer>

              <ReserveTablesText>
                {`${translate(TranslateConfig.RESERVE_TABLE_AVAILABLE)}: ${selectedReservation?.places[selectedPlaceIdx].tables || '-'}`}
              </ReserveTablesText>



              <ReservePriceText>
                {selectedReservation?.places[selectedPlaceIdx].price > 0
                  ? translate(TranslateConfig.RESERVE_PRICE_PAY).replace(
                    '{price}',
                    FormatMoney(selectedReservation?.places[selectedPlaceIdx].price),
                  )
                  : translate(TranslateConfig.RESERVE_FREE)
                }
              </ReservePriceText>



              <CouponChooseStyled
                type={'RESERVE'}
                idCompany={companySelected?.id}
                onSendCoupon={(couponCode: string) => {
                  // TODO
                }}
              />



              <PaymentChoose
                cardBrand={mainCreditCard?.card_brand}
                last4={mainCreditCard?.last4 ? validateString(mainCreditCard?.last4) : null}
                onPress={() => {
                  setPaymentModal(true);
                }}
              />

            </ReservePayContainer>

          </Content>
        )}



        <FooterChoose
          cancelDisabled={loading}
          cancelColor={loading
            ? ArrayColors.arrayDisabled
            : ArrayColors.arrayCancel
          }
          cancelLabel={
            <ButtonText
              color={Colors.white}>
              {translate(TranslateConfig.ACTION_BACK)}
            </ButtonText>
          }
          cancelPress={() => {
            cancelButtonPress();
          }}

          okDisabled={loading}
          okColor={loading || reserveData?.length <= 0
            ? ArrayColors.arrayDisabled
            : !availableUser
              ? ArrayColors.arrayAction
              : ArrayColors.arrayOk
          }
          okLabel={
            <ButtonText
              color={Colors.white}>
              {loading
                ? translate(TranslateConfig.LOADING)
                : !availableUser
                  ? translate(TranslateConfig.ACTION_ACCESS)
                  : translate(TranslateConfig.ACTION_TO_RESERVE)
              }
            </ButtonText>
          }
          okPress={() => {
            confirmRevision();
          }}
        />

      </Container>



      <SeeDatesModal
        visible={showSeeDatesModal}
        dates={dates}
        onCancelPress={() => {
          setShowSeeDatesModal(false);
        }}
        onPress={(index: number) => {
          selectDate(index);
        }}
      />



      <AddNewPersonModal
        visible={showAddPersonModal}
        canAddGhost
        onCancelPress={() => {
          setShowAddPersonModal(false);
        }}
        onPress={(person: any) => {
          setPeople([
            ...people,
            person,
          ]);
        }}
      />



      <PaymentsModal
        visible={paymentModal}
        data={cardItem}
        onCancelPress={() => {
          setPaymentModal(false);
        }}
        onPress={(card: any) => {
          setMainCreditCard(card);
          setPaymentModal(false);
        }}
      />



      <RevisionReserveModal
        visible={showConfirmation}
        data={userReserveData}
        cardLast4={mainCreditCard?.last4}
        cardBrand={mainCreditCard?.card_brand}
        onCancelPress={() => {
          setShowConfirmation(false);
        }}
        onPress={() => {
          createReservation();
        }}
      />

    </Screen>

  );
};



export default CompanyReservesScreen;
