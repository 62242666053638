export const APP_COLORS = {
  primary:                          '#009688',
  primaryDark:                      '#09403C',
  secondary:                        '#2BDA8E',
  secondaryDark:                    '#50150e',
  tertiary:                         '#FFE358',
  accent:                           '#00FFFF',
};


export const APP_DEFAULT = {
  black:                            '#000000',
  white:                            '#FFFFFF',
  grayLight:                        '#c7c7c7',
  gray:                             '#898989',
  grayDark:                         '#666666',
  muted:                            '#9FA5AA',
  shadow:                           '#292929',
  transparent:                      'transparent',
  neutral:                          'rgba(255,255,255, 0.65)',
};


export const APP_STATUS = {
  danger:                           '#F51E1D',
  attentionLight:                   '#ffa729',
  attention:                        '#FF7D35',
  attentionDark:                    '#ee5e11',
  accept:                           '#16b14c',
  disabled:                         '#949494',
  sketch:                           '#d829ff',
  // muted:                            '#9FA5AA',
};


export const APP_ITEMS = {
  appBackground:                    '#010214',
  appScrollbar:                     '#343946',
};


export const ALERTS = {
  alertOverlay:                     'rgba(0, 0, 0, 0.65)',
  alertBackground:                  '#0c1022',
  alertTitleColor:                  '#FFFFFF',
};


export const BUTTONS = {
  buttonOkDark:                     '#1B7272',
  buttonOk:                         '#11a7a1',
  buttonOkAccent:                   '#17d6cc',
  buttonOkLight:                    '#79bfb8',

  buttonAttentionDark:              '#502c11',
  buttonAttention:                  '#a35f1f',
  buttonAttentionAccent:            '#de7c21',

  buttonCancelDark:                 '#501111',
  buttonCancel:                     '#960A0A',
  buttonCancelAccent:               '#D61717',

  buttonActionDark:                 '#0b4a89',
  buttonAction:                     '#1266ba',
  buttonActionAccent:               '#208af3',

  buttonDisabledDark:               '#7d818c',
  buttonDisabled:                   '#a9adb7',

  buttonSolid:                      '#c6dbe7',
  buttonSolidDark:                  '#0C2238',
};


export const CALENDAR = {
  calendarColorGray:                'rgba(110, 110, 110, 0.3)',
  calendarColorLight:               'rgba(23, 214, 204, 0.14)',
  calendarColor:                    'rgba(23, 214, 204, 0.35)',
};


export const CARD = {
  cardBackground:                   '#1d233a',
  cardOverlay:                      'rgba(0, 0, 0, 0.7)',
  cardCarnival:                     '#C1C8CC',
};


export const CARNIVAL = {
  // carnivalPurple:                   '#5E4395',
  carnivalPurple:                   '#9000C0',
  carnivalPink:                     '#D900D2',
  carnivalBlue:                     '#029ACC',
  carnivalGreen:                    '#00c27b',
  carnivalYellow:                   '#c7a600',
  carnivalRed:                      '#FF0246',
};


export const LEVELS = {
  noneColor:                        '#404040',
  basicColor:                       '#00A5A5',
  superColor:                       '#009eed',
  persasColor:                      '#701D99',
  starColor:                        '#999E17',
  proColor:                         '#ba0808',
  kingColor:                        '#EEEB01',
};


export const MENU = {
  menuOverlay:                      'rgba(0, 0, 0, 0.8)',
  menuBackground:                   '#050615',
  menuLine:                         '#162F40',
  menuSeparator:                    '#353535', // '#404040',
  menuItem:                         '#9DA2B2',
};


export const PARENTAL = {
  plus0:                            '#018301',
  plus14:                           '#FF8C00',
  plus16:                           '#FF0000',
  plus18:                           '#343434',
};


export const PROFILES = {
  profileToolbar:                   '#0A0A23',
  profileColorTop:                  '#131a1f',
  profileColorBottom:               '#404d55',
};


export const REFRESH = {
  refreshBackground:                '#303346',
};


export const TEXT = {
  textTitle:                        '#DEDEDE',
  textDescription:                  '#878787',
  textSubtitle:                     '#9DA2B2',
  textSubDescription:               '#696E7D',
  textApp:                          '#c7c7c7',
};


export const TABLE = {
  tableBackground:                  'rgba(0, 139, 139, 0.2)',
};


export const TOOLBAR_TOP = {
  toolbarBackground:                '#050b29',
  toolbarLine:                      '#303346',
};

export const CARDS = {
  cardBackground:                   '#FFFFFF',
  cardBackgroundAccent:             '#ffb3bd',
  cardBackgroundDark:               '#ff99a7',
};


export const TOOLBAR_BOTTOM = {
  actionBackground:                 '#272A32',
  navigationBottomBackground:       '#0d0d21',
  navigationBottomLine:             '#101827',
};



const Colors = {
  ...CARDS,
  ...APP_COLORS,
  ...APP_DEFAULT,
  ...APP_STATUS,
  ...APP_ITEMS,

  ...ALERTS,
  ...BUTTONS,
  ...CALENDAR,
  ...CARD,
  ...CARNIVAL,
  ...LEVELS,
  ...MENU,
  ...PARENTAL,
  ...PROFILES,
  ...REFRESH,
  ...TEXT,
  ...TABLE,
  ...TOOLBAR_TOP,
  ...TOOLBAR_BOTTOM,
};



export default Colors;
