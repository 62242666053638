import React,
{
  useEffect,
  useState,
} from 'react';

import {
  useTranslation,
} from 'react-i18next';

import {
  useDispatch,
} from 'react-redux';

import {
  useHistory,
} from 'react-router-dom';

import {
  GetOrderUser,
} from '@noitada/axios-config/endpoints/order-sheet.endpoints';

import {
  APP_SNACKBAR_ACTION,
} from '@noitada/redux-config/reducers/app.store';

import {
  Colors,
  Images,
} from '@noitada/shared/constants';

import EOrderUser from '@noitada/shared/enums/Orders/order-user.enum';

import {
  TranslateConfig,
} from '@noitada/shared/translations';

import CardHistoryOpenOrder from '../../../../../components/Cards/CardOrders/CardHistoryOpenOrder';

import EmptyContent from '../../../../../components/Empty';
import LoadingScreen from '../../../../../components/Loadings/LoadingScreen';

import {
  FormatMoney,
} from '../../../../../config/mask.config';

import NameRoutes from '../../../../../navigation/names';

import {
  OrderListFlat,
} from './styled';



const TabOrdersOpened: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t: translate } = useTranslation();


  const [ordersResponse, setOrdersResponse] = useState<Array<any>>([]);

  const [loading, setLoading] = useState(false);
  // const [refreshing, setRefreshing] = useState(false);



  function showSnackBarProps(snackColor: string, snackText: string) {
    dispatch({
      type: APP_SNACKBAR_ACTION,
      payload: {
        visible: true,
        color: snackColor,
        text: snackText,
      },
    });
  }


  async function getOpenOrdersFunction() {
    try {
      setLoading(true);
      const response = await GetOrderUser(EOrderUser.OPEN);
      setOrdersResponse(response);
    }
    catch (error: any) {
      console.error(error);

      const message = error?.hasError && error?.message && typeof error?.message === 'string'
        ? error?.message
        : translate(TranslateConfig.ERROR_HAD);
      showSnackBarProps(Colors.danger, message);
    }
    finally {
      setLoading(false);
    }
  };


  function renderItem(item: any, index: number) {
    return (

      <CardHistoryOpenOrder
        key={index}
        id={`#${item.id}`}
        name={item?.company?.name}
        username={item?.company?.company_username}
        date={item?.checkin}
        image={item?.company?.picture}
        table={`${item?.table}`}
        quantity={item?.qtyItems}
        type={item?.type}
        price={FormatMoney((parseFloat(item?.totalConsumed)))}
        onPress={() => {
          history.push(NameRoutes.OrderSheetScreen);
        }}
      />

    );
  }


  function renderLoading() {
    return (

      <LoadingScreen
        type={'SCREEN'}
        color={Colors.primary}
      />

    );
  }


  function listEmptyComponent() {
    return (

      <EmptyContent
        image={Images.ticketsEmpty}
        title={translate(TranslateConfig.EMPTY_TICKET)}
      />

    );
  }



  useEffect(() => {
    getOpenOrdersFunction();
  }, []);



  return (

    <OrderListFlat
      loading={loading}
      data={ordersResponse}
      renderItem={renderItem}
      listLoadingComponent={renderLoading()}
      listEmptyComponent={listEmptyComponent()}
    />

  );
};



export default TabOrdersOpened;
