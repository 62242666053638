import React,
{
  useState,
  useEffect,
} from 'react';

import {
  useTranslation,
} from 'react-i18next';

import {
  useDispatch,
  useSelector,
} from 'react-redux';

import {
  GetAllUserWaitList,
} from '@noitada/axios-config/endpoints/wait-lists.endpoints';

import {
  RootState,
} from '@noitada/redux-config';

import {
  APP_SNACKBAR_ACTION,
} from '@noitada/redux-config/reducers/app.store';

import {
  Colors,
  Images,
} from '@noitada/shared/constants';

import EPeriodLists from '@noitada/shared/enums/Event/event-lists-period.enum';

import {
  TranslateConfig,
} from '@noitada/shared/translations';

import {
  formatIsToFractionDayjs,
} from '@noitada/shared/utils/date.utils';

import {
  treatErrorAndReturnColor,
  treatErrorAndReturnMessage,
} from '@noitada/shared/utils/errors.utils';

import CardWaitListsCompanyActive from '../../../../components/Cards/CardWaitLists/CardWaitListsCompanyActive';

import {
  Title,
  Screen,
  Body,
  SubTitle,
} from '../../../../components/Composh/web';

import {
  ButtonBack,
} from '../../../../components/Controls';

import EmptyContent from '../../../../components/Empty';
import HeaderScreen from '../../../../components/Headers/HeaderScreen';
import LoadingScreen from '../../../../components/Loadings/LoadingScreen';

import {
  FlatListListWaitList,
} from './styled';



const WaitListPastScreen: React.FC = () => {
  const dispatch = useDispatch();
  const { t: translate } = useTranslation();


  const user = useSelector((state: RootState) => state.user.actualUser);
  const availableUser = user?.id;


  const [waitListArray, setWaitListArray] = useState<Array<any>>([]);

  const [loading, setLoading] = useState(false);



  function showSnackBarProps(snackColor: string, snackText: string) {
    dispatch({
      type: APP_SNACKBAR_ACTION,
      payload: {
        visible: true,
        color: snackColor,
        text: snackText,
      },
    });
  }


  const getReservesFunction = async () => {
    setLoading(true);
    try {
      const response = await GetAllUserWaitList(availableUser, EPeriodLists.PAST);
      const responseData = response?.data;

      if (!responseData || responseData?.length <= 0) {
        console.error(response);
        setWaitListArray([]);
        return;
      }

      setWaitListArray(responseData);
    }
    catch (error: any) {
      console.error(error);
      setWaitListArray([]);

      const getMessageError = treatErrorAndReturnMessage(error);
      const messageError = translate(getMessageError);
      const colorError = treatErrorAndReturnColor(error);
      showSnackBarProps(colorError, messageError);
    }
    finally {
      setLoading(false);
    }
  };


  function renderItem(item: any, index: number) {
    return (

      <CardWaitListsCompanyActive
        key={index}

        position={item?.position}
        image={item?.waitListCompany?.picture}
        name={item?.waitListCompany?.name}
        description={item?.waitListCompany?.company_username
          ? `@${item?.waitListCompany?.company_username}`
          : '-'
        }
        partner={item?.waitListCompany?.partner}
        verified={item?.waitListCompany?.verified}
        time={formatIsToFractionDayjs(item?.createdAt)}
        observations={item?.observations}
        priority={item?.priority}
        status={item?.status}
      />

    );
  };


  function renderLoading() {
    return (

      <LoadingScreen
        type={'SCREEN'}
        color={Colors.primary}
      />

    );
  }


  function listEmptyComponent() {
    return (

      <EmptyContent
        image={Images.noReserveUpcoming}
        title={translate(TranslateConfig.EMPTY_WAIT_LIST_AVAILABLE)}
      />

    );
  }



  useEffect(() => {
    getReservesFunction();
  }, []);



  return (

    <Screen
      backgroundColor={Colors.appBackground}>

      <HeaderScreen
        backgroundColor={Colors.toolbarBackground}
        subBackgroundColor={Colors.toolbarBackground}
        leftIcon={
          <ButtonBack
            color={Colors.white}
          />
        }
        centerContent={
          <Body>
            <Title
              color={Colors.white}>
              {translate(TranslateConfig.WAIT_LISTS)}
            </Title>

            <SubTitle
              color={Colors.gray}>
              {translate(TranslateConfig.PAST)}
            </SubTitle>
          </Body>
        }
      />



      <FlatListListWaitList
        loading={loading}
        data={waitListArray}
        renderItem={renderItem}
        listLoadingComponent={renderLoading()}
        listEmptyComponent={listEmptyComponent()}
      />

    </Screen>

  );
};



export default WaitListPastScreen;
