import styled from 'styled-components';

import {
  Colors,
} from '@noitada/shared/constants';

import {
  Toggle,
} from '../../../components/Composh/web';

import CouponChoose from '../../../components/Payments/CouponChoose';

import {
  ContentPadderHorizontal,
} from '../../../styles/styled.layout';



export const ReservePersonContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
`;

export const ReservePersonView = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 5px;
  padding-right: 5px;
`;

export const ReservePersonContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-top: 5px;
`;

export const ReserveLabelPerson = styled.h2`
  margin-bottom: 4px;
  color: ${Colors.white};
  font-size: 15px;
`;

export const ReserveWarningPerson = styled.h2`
  color: ${Colors.attention};
  font-size: 13px;
`;



export const ReserveCountView = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const ReserveCountTitle = styled.h1`
  margin-left: 8px;
  color: ${Colors.accent};
  font-size: 28px;
`;

export const ReservePersonAlertText = styled.p`
  margin-top: 20px;
  margin-bottom: 15px;
  color: ${Colors.danger};
  text-align: center;
  font-size: 14px;
`;


export const ReservePersonsView = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 5px;
  padding-right: 5px;
  padding-bottom: 10px;
`;

export const ReserveMePersonText = styled.p`
  margin-bottom: 5px;
  color: ${Colors.buttonOkLight};
  font-size: 22px;
  font-weight: bold;
  text-align: center;
`;



export const ReserveDatesContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 5px;
  margin-bottom: 10px;
`;

export const ReservesToggleDateView = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-bottom: 10px;
`;

export const ReservesDateToggle = styled(Toggle)`
  max-width: 16.6%;
  height: 65px;
  margin-top: 5px;
  margin-left: 2px;
  margin-right: 2px;
  margin-bottom: 5px;
  padding: 0;
`;

export const ReserveDateSelectText = styled.h2`
  margin-bottom: 15px;
  color: ${Colors.primary};
  font-size: 14px;
  font-weight: 600;
  text-align: center;
`;

export const ReserveOptionsContainer = styled(ContentPadderHorizontal)`
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
`;

export const ReservesToggleOptionsView = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

export const ReservesHourToggle = styled(Toggle)`
  margin-top: 5px;
  margin-left: 4.5px;
  margin-right: 4.5px;
  margin-bottom: 5px;
`;


export const ReserveTablesText = styled.h2`
  margin-bottom: 8px;
  color: #9E9E9E;
  font-size: 13px;
  font-weight: 600;
  text-align: center;
`;

export const ReservePriceText = styled.h2`
  margin-bottom: 20px;
  color: #9E9E9E;
  font-size: 15px;
  font-weight: 600;
  text-align: center;
`;


export const ReserveOthersContainer = styled(ContentPadderHorizontal)`
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
`;

export const ReservePayContainer = styled(ContentPadderHorizontal)`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
`;



export const CouponChooseStyled = styled(CouponChoose)`
  margin-top: 10px;
  margin-bottom: 25px;
`;
