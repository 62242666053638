import React,
{
  useEffect,
  useState,
} from 'react';

import {
  useTranslation,
} from 'react-i18next';

import {
  useDispatch,
  useSelector,
} from 'react-redux';

import {
  useHistory,
} from 'react-router-dom';

import {
  GetUserCreditCard,
} from '@noitada/axios-config/endpoints/conta-global.endpoints';

import {
  PayInAppRequest,
} from '@noitada/axios-config/endpoints/order-sheet.endpoints';

import {
  RootState,
} from '@noitada/redux-config';

import {
  APP_MODAL_LOADING_ACTION,
  APP_SNACKBAR_ACTION,
} from '@noitada/redux-config/reducers/app.store';

import {
  USER_SET_CHECKIN,
} from '@noitada/redux-config/reducers/comanda.store';

import {
  ArrayColors,
} from '@noitada/shared/arrays';

import {
  Colors,
  Images,
  Values,
} from '@noitada/shared/constants';

import {
  ETokenAsync,
} from '@noitada/shared/enums';

import Vars from '@noitada/shared/environments/variables';
import {
  TranslateConfig,
} from '@noitada/shared/translations';

import {
  validateString,
} from '@noitada/shared/utils/string.utils';

import AlertMessage from '../../../../components/Alerts';
import ActionButton from '../../../../components/Buttons/ButtonAction';

import {
  Screen,
  Title,
  Container,
  Content,
} from '../../../../components/Composh/web';

import {
  ButtonBack,
} from '../../../../components/Controls';

import FooterChoose from '../../../../components/Footers/FooterChoose';
import HeaderScreen from '../../../../components/Headers/HeaderScreen';
import LoadingScreen from '../../../../components/Loadings/LoadingScreen';
import PaymentChoose from '../../../../components/Payments/PaymentChoose';

import {
  openSite,
} from '../../../../config/linking.config';

import {
  FormatMoney,
} from '../../../../config/mask.config';

import ModalFinished from '../../../../modals/ModalFinished';
import ModalOk from '../../../../modals/ModalOk';
import PaymentsModal from '../../../../modals/Payments';

import NameRoutes from '../../../../navigation/names';

import {
  ButtonText,
} from '../../../../styles/styled.layout';

import OrderSheetHistoryScreen from '../OrderSheetHistory';

import {
  ContentPadderStyled,
  ContentStyled,
  PayLogosContent,
  PayOrderSheetImage,
  PaySeparatorHorizontal,
  PayCompanyLogo,
  TotalPriceOrderView,
  TotalOrderText,
  PriceOrderText,
  OrderPaymentAppView,
  OrderPaymentPlaceView,
  OrderPaymentPlaceText,
  CouponChooseStyled,
} from './styled';



const OrderSheetPayAppScreen: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t: translate } = useTranslation();


  const params: any = history.location;


  const checkinUser = useSelector((state: RootState) => state.comanda.checkin);


  const token = localStorage.getItem(ETokenAsync.USER_TOKEN);


  const [checkIncompany, setCheckInCompany] = useState<any>();

  const [loading, setLoading] = useState(false);
  const [mounted, setMounted] = useState(false);

  const [payByNoitada] = useState<boolean>(params.state?.payApp); // ();

  const [paymentModal, setPaymentModal] = useState(false);
  const [visibleModal, setVisibleModal] = useState(false);
  const [historyModal, setHistoryModal] = useState(false);

  const [mainCreditCard, setMainCreditCard] = useState<any>(null);
  const [cardItem, setCardItem] = useState<any>(null);

  const [payOnlineModalError, setPayOnlineModalError] = useState<boolean>(false);
  const [showAddCreditCardModal, setShowAddCreditCardModal] = useState<boolean>(false);



  function showSnackBarProps(snackColor: string, snackText: string) {
    dispatch({
      type: APP_SNACKBAR_ACTION,
      payload: {
        visible: true,
        color: snackColor,
        text: snackText,
      },
    });
  }


  async function payByApp() {
    try {
      setLoading(true);
      dispatch({ type: APP_MODAL_LOADING_ACTION, payload: true });

      const payload = {
        card: mainCreditCard?.id,
        installments: 1,
      };

      const data = await PayInAppRequest(payload);

      if (data) {
        setVisibleModal(true);
        dispatch({ type: USER_SET_CHECKIN, payload: null });
      }
      else {
        setPayOnlineModalError(true);
      }
    }
    catch (error: any) {
      console.error(error);

      const message = error?.hasError && error?.message && typeof error?.message === 'string'
        ? error?.message
        : translate(TranslateConfig.ERROR_HAD);
      showSnackBarProps(Colors.danger, message);
    }
    finally {
      setLoading(false);
      dispatch({ type: APP_MODAL_LOADING_ACTION, payload: false });
    }
  };


  async function getPaymentsType() {
    try {
      setLoading(true);

      const data = await GetUserCreditCard();

      if (!data || data?.length === 0) {
        setShowAddCreditCardModal(true);
        return;
      }

      setCardItem(data);

      const mainCard = data?.filter((e: any) => e?.main === true)[0];
      setMainCreditCard(mainCard);
    }
    catch (error: any) {
      console.error(error);

      const message = error?.hasError && error?.message && typeof error?.message === 'string'
        ? error?.message
        : translate(TranslateConfig.ERROR_HAD);
      showSnackBarProps(Colors.danger, message);
    }
    finally {
      setLoading(false);
    }
  }


  async function updateForm() {
    await getPaymentsType();

    setTimeout(() => {
      setMounted(true);
    }, Values.mountTime);
  }


  function openExternalSite(link: string) {
    const commonParams = `?token=${token}`;
    openSite(`${link}${commonParams}`);
  }



  useEffect(() => {
    updateForm();
    setCheckInCompany(checkinUser?.company);
  }, []);



  return (

    <Screen
      backgroundColor={Colors.appBackground}>

      <Container>

        <HeaderScreen
          backgroundColor={Colors.toolbarBackground}
          subBackgroundColor={Colors.toolbarBackground}
          leftIcon={
            <ButtonBack
              color={Colors.white}
            />
          }
          centerContent={
            <Title
              color={Colors.white}>
              {translate(TranslateConfig.ORDERSHEET_PAY)}
            </Title>
          }
        />



        {!mounted && (
          <LoadingScreen
            type={'SCREEN'}
            color={Colors.primary}
          />
        )}



        {mounted && (
          <Content>
            <ContentPadderStyled>

              <ContentStyled>


                <PayLogosContent>
                  <PayOrderSheetImage
                    alt={'cover'}
                    src={payByNoitada
                      ? Images.ordersheetPayApp
                      : Images.ordersheetPayPlace
                    }
                  />


                  <PaySeparatorHorizontal
                    alt={''}
                    src={Images.separatorHorizontal}
                  />


                  <PayCompanyLogo
                    alt={'cover'}
                    src={checkIncompany?.picture === '' || checkIncompany?.picture === null || checkIncompany?.picture === undefined
                      ? Images.placeholderAvatarCompany
                      : checkIncompany?.picture
                    }
                  />
                </PayLogosContent>



                <TotalPriceOrderView>

                  <TotalOrderText>
                    {translate(TranslateConfig.TOTAL)}
                  </TotalOrderText>

                  <PriceOrderText>
                    {FormatMoney(checkinUser?.totalConsumed)}
                  </PriceOrderText>

                </TotalPriceOrderView>



                <ActionButton
                  title={translate(TranslateConfig.ACTION_SEE_HISTORY)}
                  onPress={() => {
                    setHistoryModal(true);
                  }}
                />

              </ContentStyled>



              {payByNoitada && mainCreditCard && (
                <OrderPaymentAppView>

                  <CouponChooseStyled
                    type={'RESERVE'}
                    idCompany={checkIncompany?.id}
                    onSendCoupon={(couponCode: string) => {
                      // TODO
                    }}
                  />


                  <PaymentChoose
                    cardBrand={mainCreditCard?.card_brand}
                    last4={mainCreditCard?.last4 ? validateString(mainCreditCard?.last4) : null}
                    onPress={() => {
                      setPaymentModal(true);
                    }}
                  />
                </OrderPaymentAppView>
              )}



              {!payByNoitada && (
                <OrderPaymentPlaceView>
                  <OrderPaymentPlaceText>
                    {translate(TranslateConfig.INTERPOLATE_ORDERSHEET_FINISH).replace(
                      '{company_name}',
                      checkIncompany?.name,
                    )}
                  </OrderPaymentPlaceText>
                </OrderPaymentPlaceView>
              )}

            </ContentPadderStyled>
          </Content>
        )}



        <FooterChoose
          hideCancelButton

          okDisabled={loading || !payByNoitada}
          okColor={loading || !payByNoitada
            ? ArrayColors.arrayDisabled
            : ArrayColors.arrayOk
          }
          okLabel={
            <ButtonText
              color={Colors.white}>
              {payByNoitada
                ? translate(TranslateConfig.ACTION_PAY)
                : translate(TranslateConfig.WAITING_PAYMENT)
              }
            </ButtonText>
          }
          okPress={() => {
            if (payByNoitada) {
              payByApp();
            }
          }}
        />

      </Container>



      <ModalOk
        visible={payOnlineModalError}
        backgroundColor={Colors.cardBackground}
        image={Images.noPayCards}
        title={translate(TranslateConfig.ERROR_PAY)}
        description={translate(TranslateConfig.ERROR_PAY_APP)}
        buttonText={translate(TranslateConfig.ACTION_CLOSE)}
        onPress={() => {
          setPayOnlineModalError(false);
        }}
      />



      <AlertMessage
        visible={showAddCreditCardModal}
        title={translate(TranslateConfig.QUESTION_ADD_CREDIT_CARD)}
        cancelText={translate(TranslateConfig.ACTION_NO)}
        cancelPress={() => {
          setShowAddCreditCardModal(false);
        }}
        okText={translate(TranslateConfig.ACTION_YES)}
        okPress={() => {
          setShowAddCreditCardModal(false);
          openExternalSite(Vars().globalAccountCreditCard);
        }}
      />



      <PaymentsModal
        visible={paymentModal}
        data={cardItem}
        onCancelPress={() => {
          setPaymentModal(false);
        }}
        onPress={(card: any) => {
          setMainCreditCard(card);
          setPaymentModal(false);
        }}
      />



      <ModalFinished
        visible={visibleModal}
        title={translate(TranslateConfig.SUCCESS_PAYED)}
        buttonText={translate(TranslateConfig.ACTION_CLOSE)}
        onPress={() => {
          history.push(NameRoutes.GridEventsScreen);
        }}
      />



      <OrderSheetHistoryScreen
        orders={checkinUser?.orders}
        total={checkinUser?.totalConsumed}
        visible={historyModal}
        onClose={() => {
          setHistoryModal(false);
        }}
      />

    </Screen>

  );
};



export default OrderSheetPayAppScreen;
