import styled from 'styled-components';

import {
  Colors,
} from '@noitada/shared/constants';



export const CalendarContainerStyle = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: 15px;
  align-items: center;
  margin-top: 15px;
  margin-bottom: 30px;
`;


export const TimeCalendar = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: 90px;
  margin-right: 20px;
`;


export const DetailsDayCalendar = styled.p`
  padding-top: 2px;
  padding-bottom: 2px;
  background-color: ${Colors.white};
  color: ${Colors.black};
  font-size: 30px;
  text-align: center;
  font-weight: bold;
`;

export const DetailsDateCalendar = styled.p`
  padding-top: 5px;
  padding-bottom: 4px;
  background-color: ${Colors.primary};
  color: ${Colors.white};
  font-size: 18px;
  text-align: center;
  font-weight: bold;
`;


export const DetailsCalendarName = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 3.5px;
  margin-bottom: 3.5px;
`;


export const CalendarInfoContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  height: 40px;
  margin-top: 5px;
  margin-bottom: 5px;
`;


export const CalendarInfoDates = styled.div`
  display: flex;
  flex-direction: column;
`;

export const CalendarTitleHeader = styled.p`
  color: ${Colors.white};
  font-size: 13px;
`;

export const CalendarSubHeader = styled.p`
  color: ${Colors.textApp};
  font-size: 16px;
`;
