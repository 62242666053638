import React from 'react';

import {
  useTranslation,
} from 'react-i18next';

import {
  SetProInstallment,
} from '@noitada/shared/config/pro-installment.config';

import {
  EProTypes,
} from '@noitada/shared/enums';

import {
  TranslateConfig,
} from '@noitada/shared/translations';

import {
  FormatMoney,
} from '../../../../../../config/mask.config';

import {
  TouchableTickets,
  TicketsPriceInfoView,
  TicketsMoreInfoTitle,
  SeeTicketsView,
  TicketsMoreInfoText,
  SeeTicketsText,
} from './styled';



export interface IProps {
  disabled?: any;
  proType: string;

  paidEvent?: boolean;
  haveTickets?: boolean;
  eventPrices?: Array<number>;

  onPress?: any;
}



const DetailsSeeTickets: React.FC<IProps> = (props: IProps) => {
  const { t: translate } = useTranslation();


  const haveTickets = props.haveTickets;
  const installment = SetProInstallment(props.proType as EProTypes);

  const paidEvent = props.paidEvent;

  const minPrice = props.eventPrices && props.eventPrices[0] ? `${FormatMoney(Math.min(...props.eventPrices))}` : '-';

  const maxPrice = props.eventPrices && props.eventPrices[0] ? `${FormatMoney(Math.max(...props.eventPrices))}` : '-';



  function renderDetailsTitle() {
    if (paidEvent) {
      return translate(TranslateConfig.PRICE_BETWEEN_MIN_MAX).replace('{minPrice}', minPrice).replace('{maxPrice}', maxPrice);
    }

    return translate(TranslateConfig.EVENT_FREE);
  }


  function renderDetailsSubTitle() {
    if (haveTickets) {
      if (installment) {
        return translate(TranslateConfig.PRICE_INSTALLMENT_VALUE).replace('{installment}', installment.toString());
      }

      return translate(TranslateConfig.TICKETS_AVAILABLE);
    }

    return translate(TranslateConfig.EMPTY_TICKET_IN_EVENT);
  }



  return (

    <TouchableTickets
      // disabled={props.disabled}
      onClick={props.onPress}>

      <TicketsPriceInfoView>
        <TicketsMoreInfoTitle>
          {renderDetailsTitle()}
        </TicketsMoreInfoTitle>


        <TicketsMoreInfoText>
          {renderDetailsSubTitle()}
        </TicketsMoreInfoText>
      </TicketsPriceInfoView>


      <SeeTicketsView
        style={{
          opacity: props.disabled
            ? 0.5
            : 1,
        }}>
        <SeeTicketsText>
          {translate(TranslateConfig.ACTION_TICKET_SEE)}
        </SeeTicketsText>
      </SeeTicketsView>

    </TouchableTickets>

  );
};



export default DetailsSeeTickets;
