/* eslint-disable import/no-duplicates */
import {
  format,
} from 'date-fns';

import {
  pt,
} from 'date-fns/locale';

import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import duration from 'dayjs/plugin/duration';
import relativeTime from 'dayjs/plugin/relativeTime';

import moment from 'moment';
import 'moment/locale/pt-br';

import {
  validateString,
} from './string.utils';

dayjs.extend(customParseFormat);
dayjs.extend(duration);
dayjs.extend(relativeTime);
dayjs.locale('pt-br');



// FIXME: Remover e deixar o DayJS
export function FormatIsoDateWithPattern(isoDate: string, pattern: string) {
  try {
    if (validateString(isoDate)) {
      const newDate = new Date(isoDate);
      const dateParse = format(newDate, pattern, { locale: pt });
      return dateParse;
    }
    else {
      return null;
    }
  }
  catch (error) {
    console.error(error);
    return null;
  }
}

export function FormatIsoDateWithPatternDayjs(isoDate, pattern: string) {
  if (!isoDate) {
    return '';
  }

  const date = dayjs(isoDate);
  const formattedDate = date.format(pattern);

  return formattedDate;
}


// export function convertDateTodayToOffsetDateTime() {
//   return moment(new Date()).format('YYYY-MM-DDTHH:mm:ss.SSS');
// }


// export function convertDateToOffsetDateTime(date: Date) {
//   return moment(date).format('YYYY-MM-DDTHH:mm:ss.SSS');
// }


// export function convertDateWithFormatToOffsetDateTime(date: Date, dateFormat: string) {
//   return moment(date, dateFormat).format('YYYY-MM-DDTHH:mm:ss.SSS');
// }


// export function convertDateWithFormatAndPattern(date: Date, dateFormat: string, pattern: string) {
//   return moment(date, dateFormat).format(pattern);
// }


export function getMomentInstance() {
  return moment();
}


export function formatDateWithPattern(date: Date, pattern: string) {
  return format(date, pattern);
}


// export function formatDateTimeWithPattern(date: number, pattern: string) {
//   return format(date, pattern);
// }

export function formatMomentWithPattern(date: Date, pattern: string) {
  return moment(date).format(pattern);
}

// export function isBefore(source: Date, target: Date) {
//   return moment(source).isBefore(target);
// }


// export function isAfter(source: Date, target: Date) {
//   return moment(source).isAfter(target);
// }


// export const shortDate = (sqlDate: any) => {
//   if (sqlDate)
//     return sqlDate.substring(8) + '/' + sqlDate.substring(5, 7);
//   else
//     return '';
// };



export function RenderHourMinutes(dateStr: string) {
  if (!validateString(dateStr)) {
    return '--:--';
  }
  const date = new Date(dateStr);
  return date.toTimeString().substring(0, 5);
}



// FIXME: Melhorar essa chamada para data-fns
export function getHoursDiff(date: Date) {
  if (!date) return '';

  const now = moment(new Date()); // Todays date
  const end = moment(date); // Another date
  const duration = now.diff(end, 'hours');
  if (duration <= 0) {
    return now.diff(end, 'minutes') + ' minutos';
  }
  return duration + ' horas';
}



export function formatIsToFractionDayjs(isoDate: any) {
  if (!isoDate) {
    return '';
  }

  const now = dayjs();
  const date = dayjs(isoDate);

  const diffInMilliseconds = now.diff(date);
  const durationObj = dayjs.duration(diffInMilliseconds);

  const days = Math.floor(durationObj.asDays());
  const hours = durationObj.hours();
  const minutes = durationObj.minutes();

  // Condicionalmente monta a string
  let formattedDate = '';

  if (days > 0) {
    formattedDate += `${days}d `;
  }

  if (hours > 0) {
    formattedDate += `${hours}h `;
  }

  formattedDate += `${minutes}m`;

  return formattedDate.trim();
}



export function formatMinuteToFractionDayjs(minutes: number) {
  if (!minutes) {
    return '';
  }

  const now = dayjs();

  // Converta minutos em milissegundos e crie uma data com essa diferença
  const date = now.subtract(minutes, 'minute').toISOString();

  // Use a função formatIsToFractionDayjs para formatar a data
  const formattedTime = formatIsToFractionDayjs(date);

  return formattedTime;
};

