import React,
{
  useState,
} from 'react';

import {
  useTranslation,
} from 'react-i18next';

import {
  useDispatch,
  useSelector,
} from 'react-redux';

import {
  useHistory,
} from 'react-router-dom';

import {
  RootState,
} from '@noitada/redux-config';

import {
  APP_SNACKBAR_ACTION,
} from '@noitada/redux-config/reducers/app.store';

import {
  USER_ADD_SACOLA,
} from '@noitada/redux-config/reducers/order-bag.store';

import {
  Colors,
  Images,
} from '@noitada/shared/constants';

import {
  TranslateConfig,
} from '@noitada/shared/translations';

import {
  validateString,
} from '@noitada/shared/utils/string.utils';

import {
  Button,
} from '../../../../components/Composh/web';

import FooterAddCart from '../../../../components/Footers/FooterAddCart';

import {
  IconClose,
  IconItemAlcoholYes,
  IconItemGlutenNo,
  IconItemLactoseNo,
  IconItemOrganicYes,
  IconItemSpicyYes,
  IconItemVeganYes,
  IconItemVegetarianYes,
} from '../../../../components/Icons';

import InputText from '../../../../components/Inputs/InputText';

import {
  FormatMoney,
} from '../../../../config/mask.config';

import NameRoutes from '../../../../navigation/names';

import {
  ItemDetailOverlay,
  OrderModalView,
  OrderModalHeader,
  OrderModalTitle,
  CloseModalButton,
  ItemInfoContent,
  ItemOrderViewImage,
  ItemOrderImage,
  ItemTypesOrderView,
  ItemTypesOrderIcon,
  ItemTypesViewOrderLabel,
  ItemTypesOrderLabel,
  ItemAllDetailsView,
  ItemGroupsExtrasContent,
  ItemTitleModal,
  ItemDescriptionText,
  ItemBadgeView,
  ItemBadgeComp,
  ItemBadgeText,
  ItemPricesFooterContent,
  ItemMapView,
  PriceGroupContent,
  OrderButton,
  ItemGroupDescriptionView,
  ItemGroupNameText,
  ItemGroupQuantityText,
  ItemGroupCodeText,
  ItemPriceText,
} from './styled';



export interface IProps {
  visible?: boolean;
  item?: any;
  idCompany?: any;
  selectProd?: any;
  onCancelPress?: any;
  onSelectProd?: any;
}



const MenuOrderDetails: React.FC<IProps> = (props: IProps) => {
  const visibleModal = props.visible;
  if (!visibleModal) {
    return null;
  }


  const history = useHistory();
  const dispatch = useDispatch();
  const { t: translate } = useTranslation();


  const user = useSelector((state: RootState) => state.user.actualUser);
  const availableUser = user?.id;

  const checkinUser: any = useSelector((state: RootState) => state.comanda.checkin);


  const [comments, setComments] = useState('');



  function showSnackBarProps(snackColor: string, snackText: string) {
    dispatch({
      type: APP_SNACKBAR_ACTION, payload: {
        visible: true,
        color: snackColor,
        text: snackText,
      },
    });
  }


  function enableButtonBag() {
    if (checkinUser && checkinUser.status === 'APPROVED' && checkinUser.company.id === props.idCompany) {
      return true;
    }
    else {
      return false;
    }
  }


  async function processOrder(itemGroup: any, quantity: number) {
    dispatch({ type: USER_ADD_SACOLA, payload: { itemGroup, itemSale: { ...props.item, comments }, quantity: quantity } });
    showSnackBarProps(Colors.accept, translate(TranslateConfig.SUCCESS_ADD_ITEM_BAG));
    props.onCancelPress();
  }


  async function AddItemOrOpenLoginScreen(item: any, quantity: number) {
    // FIXME: Não permitir itens de outros perfis, apenas do próprio local que fez checkin
    if (availableUser && checkinUser !== null) {
      await processOrder(item, quantity);
    }
    else {
      history.push(NameRoutes.AccessScreen);
    }
  }


  function renderItemBadge(label: string, backgroundColor: string) {
    return (

      <ItemBadgeComp
        backgroundColor={backgroundColor}>
        <ItemBadgeText
          color={Colors.white}>
          {label}
        </ItemBadgeText>
      </ItemBadgeComp>

    );
  }


  function renderObrigatoryItem(itemGroup: any, index: number) {
    return (

      <ItemMapView
        key={index}
        onClick={() => {
          if (enableButtonBag()) {
            props?.onSelectProd(itemGroup);
          }
        }}>

        <PriceGroupContent>

          {availableUser && (
            <OrderButton>
              <Button.Circle
                margin={false}
                active={props.selectProd === itemGroup}
                size={16}
                activeColor={Colors.buttonOk}
                deactiveColor={Colors.textSubtitle}
              />
            </OrderButton>
          )}



          <ItemGroupDescriptionView>

            <ItemGroupNameText>
              {validateString(itemGroup?.name) || '-'}
            </ItemGroupNameText>


            <ItemGroupQuantityText>
              {`${translate(TranslateConfig.PERSON_TO)}: ${validateString(itemGroup?.quantity) || '-'} ${translate(TranslateConfig.PERSONS)}`}
            </ItemGroupQuantityText>


            <ItemGroupCodeText>
              {validateString(itemGroup?.sale_id)
                ? `${translate(TranslateConfig.CODE_ABREV)}: ${validateString(itemGroup?.code) || '-'}`
                : '-'
              }
            </ItemGroupCodeText>

          </ItemGroupDescriptionView>

        </PriceGroupContent>



        <ItemPriceText>
          {itemGroup?.price > 0
            ? FormatMoney(itemGroup?.price)
            : '-'
          }
        </ItemPriceText>

      </ItemMapView>

    );
  }



  return (

    <ItemDetailOverlay
      visible={visibleModal}
      showBackground={true}
      overlayColor={Colors.menuOverlay}
      onOverlayPress={props.onCancelPress}>

      <OrderModalView>

        <OrderModalHeader>
          <OrderModalTitle>
            {translate(TranslateConfig.DETAILS_ITEM)}
          </OrderModalTitle>

          <CloseModalButton
            onClick={props.onCancelPress}>
            <IconClose
              color={Colors.white}
            />
          </CloseModalButton>
        </OrderModalHeader>



        <ItemInfoContent>

          <ItemOrderViewImage>
            <ItemOrderImage
              alt={'Image'}
              src={
                props.item?.picture === '' || props.item?.picture === null || props.item?.picture === undefined
                  ? Images.backgroundFooterAbout
                  : props.item?.picture
              }
            />
          </ItemOrderViewImage>



          {(props.item?.alcoholic || props.item?.vegetarian || props.item?.vegan || props.item?.organic || props.item?.no_lactose || props.item?.no_glutem) && (
            <ItemTypesOrderView>

              {props.item?.alcoholic && (
                <ItemTypesOrderIcon>
                  <IconItemAlcoholYes
                    color={Colors.white}
                    size={20}
                  />

                  <ItemTypesViewOrderLabel>
                    <ItemTypesOrderLabel>
                      {translate(TranslateConfig.ALCOHOLIC)}
                    </ItemTypesOrderLabel>
                  </ItemTypesViewOrderLabel>
                </ItemTypesOrderIcon>
              )}


              {props.item?.spicy && (
                <ItemTypesOrderIcon>
                  <IconItemSpicyYes
                    color={Colors.white}
                    size={20}
                  />

                  <ItemTypesViewOrderLabel>
                    <ItemTypesOrderLabel>
                      {translate(TranslateConfig.SPICY)}
                    </ItemTypesOrderLabel>
                  </ItemTypesViewOrderLabel>
                </ItemTypesOrderIcon>
              )}


              {props.item?.vegetarian && (
                <ItemTypesOrderIcon>
                  <IconItemVegetarianYes
                    color={Colors.white}
                    size={20}
                  />

                  <ItemTypesViewOrderLabel>
                    <ItemTypesOrderLabel>
                      {translate(TranslateConfig.VEGETARIAN)}
                    </ItemTypesOrderLabel>
                  </ItemTypesViewOrderLabel>
                </ItemTypesOrderIcon>
              )}


              {props.item?.vegan && (
                <ItemTypesOrderIcon>
                  <IconItemVeganYes
                    color={Colors.white}
                    size={20}
                  />

                  <ItemTypesViewOrderLabel>
                    <ItemTypesOrderLabel>
                      {translate(TranslateConfig.VEGAN)}
                    </ItemTypesOrderLabel>
                  </ItemTypesViewOrderLabel>
                </ItemTypesOrderIcon>
              )}


              {props.item?.organic && (
                <ItemTypesOrderIcon>
                  <IconItemOrganicYes
                    color={Colors.white}
                    size={20}
                  />

                  <ItemTypesViewOrderLabel>
                    <ItemTypesOrderLabel>
                      {translate(TranslateConfig.ORGANIC)}
                    </ItemTypesOrderLabel>
                  </ItemTypesViewOrderLabel>
                </ItemTypesOrderIcon>
              )}


              {props.item?.no_lactose && (
                <ItemTypesOrderIcon>
                  <IconItemLactoseNo
                    color={Colors.white}
                    size={20}
                  />

                  <ItemTypesViewOrderLabel>
                    <ItemTypesOrderLabel>
                      {translate(TranslateConfig.NO_LACTOSE)}
                    </ItemTypesOrderLabel>
                  </ItemTypesViewOrderLabel>
                </ItemTypesOrderIcon>
              )}


              {props.item?.no_glutem && (
                <ItemTypesOrderIcon>
                  <IconItemGlutenNo
                    color={Colors.white}
                    size={20}
                  />

                  <ItemTypesViewOrderLabel>
                    <ItemTypesOrderLabel>
                      {translate(TranslateConfig.NO_GLUTEN)}
                    </ItemTypesOrderLabel>
                  </ItemTypesViewOrderLabel>
                </ItemTypesOrderIcon>
              )}

            </ItemTypesOrderView>
          )}



          {(props.item?.type === 'COMBO' || props.item?.double) && (
            <ItemBadgeView>

              {props.item?.type === 'COMBO' && renderItemBadge(
                translate(TranslateConfig.COMBO),
                Colors.attentionLight,
              )}

              {props.item?.double && renderItemBadge(
                translate(TranslateConfig.ITEM_DOUBLE),
                Colors.primaryDark,
              )}

            </ItemBadgeView>
          )}



          <ItemAllDetailsView>

            <ItemGroupsExtrasContent>

              <ItemTitleModal>
                {validateString(props.item?.name) || '-'}
              </ItemTitleModal>



              <ItemDescriptionText>
                {validateString(props.item?.description) || '-'}
              </ItemDescriptionText>



              {/* TODO: Fazer Badges
              <ItemBadgeView>
                <Badge
                  backgroundColor={Colors.primary}
                  value={'COMBO'}
                />
              </ItemBadgeView>
              */}


              {/* <SeparatorItemModal
                height={1}
                width={'100%'}
                color={'gray'}
              /> */}



              <ItemPricesFooterContent>
                {props.item?.group.map((itemGroup: any, index: number) => (
                  renderObrigatoryItem(itemGroup, index)
                ))}
              </ItemPricesFooterContent>

            </ItemGroupsExtrasContent>



            {availableUser && (
              <InputText
                type={'TEXT'}
                labelText={translate(TranslateConfig.COMMENTS)}
                placeholderText={translate(TranslateConfig.OPTIONAL)}
                // helpText={errors.idShort}
                countLimit={256}
                value={comments}
                onChange={(rawText: string) => {
                  setComments(rawText);
                }}
              // onBlur={handleBlur('idShort')}
              />
            )}

          </ItemAllDetailsView>

        </ItemInfoContent>



        {availableUser && (
          <FooterAddCart
            selectItem={props?.selectProd}
            onPress={(item: any, quantity: number) => {
              AddItemOrOpenLoginScreen(item, quantity);
            }}
          />
        )}

      </OrderModalView>

    </ItemDetailOverlay>

  );
};



export default MenuOrderDetails;
