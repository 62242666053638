import React,
{
  useEffect,
  useState,
} from 'react';

import {
  useTranslation,
} from 'react-i18next';

import {
  Colors,
  Values,
} from '@noitada/shared/constants';

import {
  TranslateConfig,
} from '@noitada/shared/translations';

import {
  FormatIsoDateWithPattern,
} from '@noitada/shared/utils/date.utils';

import {
  Overlay,
  Title,
} from '../../../../components/Composh/web';

import {
  ButtonBack,
} from '../../../../components/Controls';

import HeaderScreen from '../../../../components/Headers/HeaderScreen';
import LoadingScreen from '../../../../components/Loadings/LoadingScreen';

import {
  SeeDatesContainer,
  SeeDatesFlat,
  SeeDatesButton,
  SeeDatesText,
} from './styled';

const width = 600;
const height = 500;



export interface IProps {
  visible?: boolean;
  dates: any;
  onCancelPress?: any;
  onPress?: any;
}



const SeeDatesModal: React.FC<IProps> = (props: IProps) => {
  const visibleModal = props.visible;
  if (!visibleModal) {
    return null;
  }

  const { t: translate } = useTranslation();


  const datesArray = props.dates;

  const widthLine = width - 320;
  const heightLine = height - 90;

  const [loading, setLoading] = useState(false);



  function onCancel() {
    props.onCancelPress();
  }


  function renderItem(item: any, index: number) {
    return (

      <SeeDatesButton
        key={index}
        // activeOpacity={Sizes.ButtonActiveOpacity}
        onClick={() => {
          props.onPress(index);
          onCancel();
        }}>
        <SeeDatesText>
          {FormatIsoDateWithPattern(item, 'dd/MM/yyyy')}
        </SeeDatesText>
      </SeeDatesButton>

    );
  }


  function renderFooter() {
    if (!loading) {
      return null;
    }

    if (datesArray.length !== 0) {
      return null;
    }

    return (

      <LoadingScreen
        type={'FOOTER'}
        color={Colors.primary}
      />

    );
  }



  useEffect(() => {
    setLoading(true);

    setTimeout(() => {
      setLoading(false);
    }, Values.mountTime);
  }, []);



  return (

    <Overlay
      visible={props.visible}
      showBackground={true}
      overlayColor={Colors.menuOverlay}
      onOverlayPress={props.onCancelPress}>

      <SeeDatesContainer
        width={widthLine}
        height={heightLine}>

        <HeaderScreen
          transparent
          centerContent={
            <Title
              color={Colors.white}>
              {translate(TranslateConfig.DATES_AVAILABLE)}
            </Title>
          }
          rightIcon={
            <ButtonBack
              close
              color={Colors.white}
              onPress={() => {
                onCancel();
              }}
            />
          }
        />



        <SeeDatesFlat style={{
          justifyContent: loading && datesArray?.length === 0 ? 'center' : 'flex-start',
        }}>

          {renderFooter()}


          {!loading && datesArray?.length !== 0 && datesArray?.map((item: any, index: any) => (
            renderItem(item, index)
          ))}

        </SeeDatesFlat>

      </SeeDatesContainer>

    </Overlay>

  );
};



export default SeeDatesModal;
